//Import des composants utiles
import React from 'react';
import store from '../../Store/Store';
import API from '../../api';
import Navbar from '../../components/Navbar';
import axios from 'axios';
import moment from 'moment';
import { sortAntiChronologicaly} from '../../utils'

//Import des composants de design
import { Typography, Layout, Col, Row, List, Empty, Card, Menu } from 'antd';
import { AdminUserProfileCard } from '../../components/LeftPanel';
import { TrainingCard } from '../../components/MiddlePanel';
import { AddACertificateToEmployee } from '../../components/Modal';
import { GoBackTitle, Loading, UserCertificatesCard, UserInvoicesCard } from '../../components/Component'
const { Title } = Typography;
const { Header, Sider, Content } = Layout;

//Page profile de l'utilisateur
class User extends React.Component{
    constructor(props){
        super(props)

        //Récupération des informations dans le store
        this.state = {
            selected_user : store.getState().user.selected,
            current:"trainings",
            user: null,
            trainings: [],
            invoices: [],
            certificates: [],
            companies: [],
        }

        //Génération d'un token d'arrêt des appels
        this.source = axios.CancelToken.source();

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;

        this.handleClick = this.handleClick.bind(this);
        this.refreshCertificates = this.refreshCertificates.bind(this);
        this.refreshUserDate = this.refreshUserData.bind(this);
    }
    
    componentDidMount(){
        this._isMounted = true;
        this.refreshUser();
    }

    //Prise en charge du changement de menu
    handleClick(event) {
        this.setState({ current: event.key })
    };

    componentWillUnmount(){
        this._isMounted = false;
        this.source.cancel("Unmounted Component");
    }

    //Récupération de toutes les informations de l'utilisateur
    refreshUser(){
        this.refreshCertificates();
        this.refreshCompanies();
        this.refreshUserData();
        this.refreshTrainings();
        this.refreshInvoices();
    }

    //Récupération des occurences de formation réalisés
    async refreshTrainings(){
        API.get(`/api/users/${this.state.selected_user}/training-occurences`)
        .then((res) => {
            let trainings = res.data;

            const promises = [];

            promises.push(Promise.all([...new Set(trainings.map((tr_occ) => tr_occ.training_id))].map(training_id => API.get('/api/trainings/'+training_id))).then(res => {
                const training = res.map(o => o.data);
                trainings.forEach(occ => {
                    occ.training = training.find(t => t.id === occ.training_id);
                });
            }));

            promises.push(...trainings.map((occ) => {
                return API.get('/api/training-occurences/'+occ.id+'/durations').then((res) => {
                    occ.durations = res.data;
                    occ.lastDurationDate = moment.max([].concat(...occ.durations.map((dur) => [moment(dur.start_date), moment(dur.end_date)]))).toISOString();
                });
            }));

            Promise.all(promises).then(() =>{
                this.setState({trainings: trainings});
            });
        });
    }

    //Récupération des informations sur l'utilisateur
    refreshUserData(){
        API.get(`/api/users/${this.state.selected_user}`)
        .then((res) => {            
            this.setState({user: res.data});
        });
    }

    //Récupération des certificats
    refreshCertificates(){
        API.get(`/api/users/${this.state.selected_user}/certificates`)
        .then((res) => {            
            this.setState({certificates: res.data});
        });
    }

    //Récupération des factures
    refreshInvoices(){
        API.get(`/api/users/${this.state.selected_user}/files?type=FILE_INVOICE`)
        .then((res) => {            
            this.setState({invoices: res.data});
        });
    }

    //Récupération des entreprises
    refreshCompanies(){
        API.get(`/api/users/${this.state.selected_user}/companies`)
        .then((res) => {            
            this.setState({companies: res.data});
        });
    }

    render(){
        const dates = [];
        this.state.trainings.forEach((occ) => {
            occ.durations.forEach((duration) => {
                dates.push(moment(duration.start_date));
                dates.push(moment(duration.end_date));
            });
        });

        const trainings = [];
        this.state.trainings.forEach((occ) => {
            trainings.push({
                'id': occ.training_id,
                'name': occ.training.name,
                'date': occ.lastDurationDate,
            })
        });

        //Liste des formations prochaines
        const listNextTrainingOccurences = () => {
            return this.state.trainings.filter((tr_occ) => (
                tr_occ.durations.some((duration) => 
                    moment(duration.end_date).isAfter(new Date().now, 'day'))
                )
            ); 
        }

        //Liste des formations effectuées
        const listDoneTrainingOccurences = () => {
            return this.state.trainings.filter((tr_occ) => (
                tr_occ.durations.every((duration) => 
                    moment(duration.end_date).isBefore(new Date().now, 'day'))
                )
            ); 
        }

        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar />
                </Sider>
                {this.state.user
                ?<Layout className="main-container">
                    <Header>
                        <GoBackTitle route="/aclients" title="Retour aux clients"/>
                    </Header>
                    <Content className="app-content">
                        <Row gutter={[48, 48]}>
                            <Col span={6}>
                                {/* Carte utilisateur */}
                                <AdminUserProfileCard user={this.state.user} companies={this.state.companies} refresh_handler={() => this.refreshUserData()}/>
                            </Col>

                            <Col span={8}>
                                {/* NavMenu */}
                                <Menu onClick={(e) => this.handleClick(e)} mode="horizontal" selectedKeys={this.state.current}>
                                    <Menu.Item key="trainings">
                                        Formations
                                    </Menu.Item>
                                    <Menu.Item key="donetrainings">
                                        Formations Réalisées
                                    </Menu.Item>
                                </Menu>
                                {this.state.current==="trainings"? 
                                <List className="cardList" size="small" dataSource={listNextTrainingOccurences()} renderItem={training_occurence => (
                                    <TrainingCard trainingOccurence={training_occurence} isDone={false}/>
                                )}
                                pagination={{
                                    pageSize: 4,
                                    hideOnSinglePage:true,
                                    simple:true,
                                    position:'top',
                                }}
                                locale={{emptyText:<Empty description="Aucune formation" image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}>
                                </List>:
                                <List className="cardList" size="small" dataSource={listDoneTrainingOccurences()} renderItem={training_occurence => (
                                    <TrainingCard trainingOccurence={training_occurence} isDone={true}/>
                                )}
                                pagination={{
                                    pageSize: 4,
                                    hideOnSinglePage:true,
                                    simple:true,
                                    position:'top',
                                }}
                                locale={{emptyText:<Empty description="Aucune formation réalisée" image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}>
                                </List>}
                            </Col>

                            {/* Certificats et Factures*/}
                            <Col span={8}>    

                                {/*Affichage des certificats*/}                             
                                <Card>
                                    <Title level={5}>Certificats</Title>
                                    <List
                                        size="small"
                                        className="cardList"
                                        dataSource={this.state.certificates}
                                        renderItem={item => (
                                            <UserCertificatesCard user={this.state.user} key={item.id} certificate={item} refresh_handler={this.refreshCertificates}/>
                                        )}
                                        pagination={{
                                            pageSize: 4,
                                            hideOnSinglePage:true,
                                            simple:true,
                                        }}
                                        locale={{emptyText:<Empty description="Aucun certificat" image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                                    />
                                    <Row justify="center" style={{marginTop:25}}>
                                        <AddACertificateToEmployee employee={this.state.user} refresh_handler={() => this.refreshCertificates()}/>
                                    </Row>
                                </Card>

                                {/*Affichage des factures*/}
                                <Card>
                                    <Title level={5}>Factures</Title>                              
                                    <List
                                        size="small"
                                        className="cardList"
                                        dataSource={this.state.invoices.length > 0?sortAntiChronologicaly(this.state.invoices, "created_at"):[]}
                                        renderItem={item => (
                                            <UserInvoicesCard key={item.id} invoice={item}/>
                                        )}
                                        pagination={{
                                            pageSize: 4,
                                            hideOnSinglePage:true,
                                            simple:true,
                                        }}
                                        locale={{emptyText:<Empty description="Aucune facture" image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                                    />
                                </Card>
                            </Col>                        
                        </Row>
                    </Content>
                </Layout>:<Loading />}
            </Layout>
        );
    }
}

export default User;