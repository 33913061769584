import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {List} from 'antd';

import AOS from 'aos';

//Page des actualités
export default class Actualities extends Component {
    constructor(props){
        super(props);

        this.state = {
            actualities:[]
        };

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    async componentDidMount(){
        this._isMounted = true;

        fetch("/api/news?number=30")
            .then(res => res.json())
            .then(
            (result) => {
                if(this._isMounted){

                    result.forEach(element => {
                        var datesplit = element.updated_at.split("-");
                        var day = datesplit[2].split("T");
                        var date = (day[0]+"/"+datesplit[1]+"/"+datesplit[0]);
                        element["date"]=date;
                    });

                    this.setState({actualities: result});
                } 
            }
        )           
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    render(){
        AOS.init();

        var i = 100;

        if(this.state.actualities.length>0){

            return (
                <List
                    itemLayout="horizontal"
                    pagination={{
                        pageSize: 3,
                    }}
                    dataSource={this.state.actualities}
                    renderItem={item => (
                    <List.Item
                        key={item.id}
                        data-aos="fade-up"
                        data-aos-delay="100"
                    >
                        <article><h5>{item.title}</h5><span>{item.date}</span><p>{item.data}</p></article>
                    </List.Item>
                )}/>
            );
        }else{
            return( 
                <ul id="noactualities">
                    <li data-aos="fade-up" data-aos-delay={100}><article><h5>Pas d'actualité</h5><p>Nous n'avons pas d'actualité pour le moment.</p></article></li>
                </ul>
                )
        }



    }
}

if (document.getElementById('actualities')) {
    ReactDOM.render(<Actualities />, document.getElementById('actualities'));
}