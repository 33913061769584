//Import des composants utiles
import React from 'react';
import API from '../api';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import store from '../Store/Store';
import { ActionCreators } from '../Store/Action';
import { PasswordReset } from '../components/Modal';
import { CheckNationalNumber } from '../utils';

//Import des composants de design
import { LeftOutlined } from '@ant-design/icons';
import { Form, Input, Card, Button, Typography, Select, Row, Col } from 'antd';
const { Text } = Typography;

//Page de création du compte de l'utilisateur
class Invitation extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            first_name: undefined,
            last_name: undefined,
            password_confirm: undefined,
            password: undefined,
            national_number: undefined,
            email: undefined,
            error_message: "",
            redirect: false,
            errorUniqueMail: false,
            errorUniqueNN: false,
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;

        this.onFinish = this.onFinish.bind(this);
    }

    //Récupération des variables passé dans l'url
    getUrlVars() {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    //Vérification des champs
    verifyField(){
        if(this.state.first_name === undefined || this.state.first_name === ""){
            if(this._isMounted)
                this.setState({error_message: "Veuillez remplir tous les champs !"})
            return false;
        }

        if(this.state.last_name === undefined || this.state.last_name === ""){
            if(this._isMounted)
                this.setState({error_message: "Veuillez remplir tous les champs !"})
            return false;
        }

        if(this.state.national_number === "" || this.state.national_number === ""){
            if(this._isMounted)
                this.setState({error_message: "Veuillez remplir tous les champs !"})
            return false;
        }
        
        if(this.state.email == ""){
            if(this._isMounted)
                this.setState({error_message: "Veuillez remplir tous les champs !"})
            return false;
        }

        if(this.state.password == "" || this.state.password.length < 8 || this.state.password != this.state.password_confirm){
            if(this._isMounted)
                this.setState({error_message: "Mots de passe différents ou trop court !"})
            return false;
        }

        return true;
    }

    //Prise en charge de la modification des champs
    handleChange(event){
        if(this._isMounted)
            this.setState({[event.target.name]: event.target.value})
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    async onFinish(values) {
        this.setState({
            errorUniqueMail: false,
            errorUniqueNN: false
        });

        if(this.verifyField()){
            API.post('/api/users',{
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                national_number: this.state.national_number,
                email:this.state.email,
                password_confirmation: this.state.password_confirm,
                password: this.state.password,
                role:'ROLE_USER'
            })
            .then((res) => {
                if(this._isMounted){
                    store.dispatch(ActionCreators.register(res.data));
                    this.setState({redirect: true});
                }
            }).catch(error => {
                if (error.response.data.errors.national_number && error.response.data.errors.national_number[0] === "The national number has already been taken."){
                    this.setState({
                        errorUniqueNN: true,
                    });
                }

                if (error.response.data.errors.email && error.response.data.errors.email[0] === "The email has already been taken."){
                    this.setState({
                        errorUniqueMail: true,
                    });
                }
            }) 
        }
    }

    render(){
        if(this.state.redirect)
            return <Redirect to='/' />;

        const validatePasswordRule = (rule, value, callback) => {

        }

        let actions = [<Button form="registerClient" type="primary" htmlType="submit" size='large'>Créer mon compte</Button>];
        if(this.state.errorUniqueMail || this.state.errorUniqueNN){
            actions=[<Button form="registerClient" type="primary" htmlType="submit" size='large'>Créer mon compte</Button>,<PasswordReset />];
        }
        
        return (

            <Row id="register" justify="center" align="middle">
                <Col span={8}>
                    <Card
                        title="Création de votre compte"
                        actions={actions}
                    >
                        <Link to="/" className="backbutton"><Button type="primary" icon={<LeftOutlined />}/></Link>
                        <Form id="registerClient" layout="vertical" onFinish={this.onFinish}> 
                            <Form.Item hasFeedback label="Prénom" name="first_name" rules={[{required: true, message: 'Veuillez entrer votre prénom'}]}>     
                                <Input autoComplete={"first_name"} name={"first_name"} onChange={this.handleChange.bind(this)} placeholder={"Entrer votre prénom"}/>
                            </Form.Item>
                            <Form.Item hasFeedback label="Nom" name="last_name" rules={[{required: true, message: "Veuillez entrer votre nom"}]}>
                                <Input autoComplete={"last_name"} name={"last_name"} onChange={this.handleChange.bind(this)} placeholder={"Entrer votre nom"}/>
                            </Form.Item>
                            <Form.Item hasFeedback label="Numéro national" name="national_number" rules={[{required: true, message: "Veuillez entrer votre numéro national"}]}>
                                <Input autoComplete={"national_number"} name={"national_number"} onChange={this.handleChange.bind(this)} placeholder={"Entrer votre numéro National"}/>
                            </Form.Item>
                            { this.state.errorUniqueNN &&
                                <Text type="danger"> Le numéro national que vous avez choisi est déjà utilisée</Text>
                            }
                            <Form.Item hasFeedback label="Email" name="email" style={{fontWeight:500}} rules={[{required: true, message: 'Veuillez entrer une adresse mail'}, {type: "email", message:"Veuillez entrer une adresse mail valide"}, ]}>
                                <Input autoComplete={"username"} name={"email"} onChange={this.handleChange.bind(this)} placeholder={"Entrer votre adresse mail"}/>
                            </Form.Item>
                            { this.state.errorUniqueMail &&
                                <Text type="danger"> L'adresse mail que vous avez choisi est déjà utilisée</Text>
                            }
                            <Form.Item hasFeedback label="Mot de passe" name="password" rules={[{required: true, message: 'Veuillez entrer un mot de passe'},{min:8, message: "Le mot de passe doit faire au minimum 8 caractères"}]}>
                                <Input.Password name={"password"} onChange={this.handleChange.bind(this)} placeholder={"Entrer un mot de passe"}/>
                            </Form.Item>
                            <Form.Item hasFeedback label="Confirmation du mot de passe" name="password_confirm" rules={[{required: true, message: 'Veuillez confirmer le mot de passe'},({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('Les deux mot de passe ne concordent pas !'));
                                },
                            }),]}>
                                <Input.Password name={"password_confirm"} onChange={this.handleChange.bind(this)} visibilityToggle={false} placeholder={"Confirmer votre mot de passe"}/>
                            </Form.Item>
                        </Form>
                        {this.state.error_message !== "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                        }
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.profile,
        trainings: state.trainings.trainings,
        trainers: state.trainers.trainers,
        users: state.users.users
    };
};

export default connect(mapStateToProps)(Invitation);