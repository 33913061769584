import moment from 'moment';

//Convertion du mois en string
function GetMonth(month){
	switch(month){
        case "01":
            return "Janvier"
        case "02":
            return "Février"
        case "03":
            return "Mars"
        case "04":
            return "Avril"
        case "05":
            return "Mai"
        case "06":
            return "Juin"
        case "07":
            return "Juillet"
        case "08":
            return "Aout"
        case "09":
            return "Septembre"
        case "10":
            return "Octobre"
        case "11":
            return "Novembre"
        case "12":
            return "Décembre"
    }
}

//Convertion de la date
export function ConvertDate(olddate){
    const year = olddate.split("T")[0].split("-")[0]
    const month = olddate.split("T")[0].split("-")[1]
    const day = olddate.split("T")[0].split("-")[2]
    const monthString = GetMonth(olddate.split("T")[0].split("-")[1])
    const hour = olddate.split("T")[1].split(":")[0]
    const min = olddate.split("T")[1].split(":")[1]

	const date = {
        "year":year,
        "month":month,
        "day":day,
        "monthString":monthString,
        "hour": hour,
        "min": min,
    }

	return date;
}
export function sortAlphabeticaly(array, propertyName){
    return array.sort(function compare(a, b) {
        if (a[propertyName] < b[propertyName])
           return -1;
        if (a[propertyName] > b[propertyName] )
           return 1;
        return 0;
      });
}

export function sortChronologicaly(array,propertyName){
    if(array==null) {
        console.error("Le tableau à trier chronologiquement est NULL")
        return [];
    }
    if(array.every((element)=>propertyName in element) == false){        
        console.error(`Certain élément du tableau à trier chronologiquement n'ont pas la proriété "${propertyName}". Le tableau n'a pas été trié.`)
        return array;
    }   
    if(array[0][propertyName] instanceof Date){
        return array.sort((a, b) => a[propertyName].getTime() - b[propertyName].getTime());
    }
    return array.sort((a, b) => Date.parse(a[propertyName]) - Date.parse(b[propertyName]));
    
}

export function sortAntiChronologicaly(array,propertyName){
    if(array==null) {
        console.error("Le tableau à trier chronologiquement est NULL")
        return [];
    }
    if(array.every((element)=>propertyName in element) == false){        
        console.error(`Certain élément du tableau à trier chronologiquement n'ont pas la proriété "${propertyName}". Le tableau n'a pas été trié.`)
        return array;
    }   
    if(array[0][propertyName] instanceof Date){
        return array.sort((a, b) => b[propertyName].getTime() - a[propertyName].getTime());
    }
    return array.sort((a, b) => Date.parse(b[propertyName]) - Date.parse(a[propertyName]));
    
}

//Vérification du numéro de registre national
export function CheckNationalNumber(nationalNumber){
    let birthYear = parseInt(nationalNumber.slice(0,2));

    if(birthYear >= 0 && birthYear <= 50){
        return nationalNumber.length===11 && (97-(parseInt(2+nationalNumber.slice(0,9))%97))===parseInt(nationalNumber.slice(9,11)) && moment(["20"+nationalNumber.slice(0,2),nationalNumber.slice(2,4),nationalNumber.slice(4,6)]).isValid()
    }
            
    return nationalNumber.length===11 && (97-(parseInt(nationalNumber.slice(0,9))%97))===parseInt(nationalNumber.slice(9,11)) && moment(["20"+nationalNumber.slice(0,2),nationalNumber.slice(2,4),nationalNumber.slice(4,6)]).isValid()
}

//Récupération de la date d'expiration
export function GetExpirationDate(date, expiration){
    let validity = expiration.substring(1);
    let years=0;
    let month=0;
    if(validity.includes('Y') && !validity.includes('M')){
        years=validity.split('Y')[0];
        month = years*12;
    }
    if(validity.includes('M') && !validity.includes('Y')){
        month=validity.split('M')[0];
    }
    if(validity.includes('M') && validity.includes('Y')){
        years=validity.split('Y')[0];
        month=(validity.split('M')[0]).split('Y')[1];
        month = years*12+month;
    }

    return new Date(date.setMonth(date.getMonth()+month));
}