//Import des composants utiles
import React from 'react';
import store from '../../Store/Store';
import { ActionCreators } from '../../Store/Action'
import { connect } from 'react-redux';
import API from '../../api';
import Navbar from '../../components/Navbar';

//Import des composants de design
import { Typography, Layout, Card, Input, Row, Col, Empty, List } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import { GoToButton } from '../../components/Component'
const { Title } = Typography;
const { Header, Sider, Content } = Layout;
const { Search } = Input;
const { Meta } = Card;

//Carte de la liste d'entreprise
export function CompanyBigCard(props){
    return (
        <Card
            cover={<TeamOutlined />}
            actions={[<GoToButton id="editbtn" route={"/company"} element_id={props.data.id}/>]}
        >
            <Meta title={props.data.company_name} />
        </Card>
    );
}

//Page de listing des entreprises de l'utilisateur
class Companies extends React.Component{
    constructor(props){
        super(props)

        //Récupération des informations dans le store
        this.state = {
            ...store.getState(),
            companies : [],
            searchText : '',
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
        this.refreshCompanies = this.refreshCompanies.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
    }

    componentDidMount(){
        this._isMounted = true;

        this.refreshCompanies();
    }

    //Chargement de la liste de toutes les entreprises
    refreshCompanies(){
        if(this.state.user.isLogged){
            API.get(`api/users/${this.state.user.profile.id}/companies`)
            .then(companies_data => {
                const companies = companies_data.data.filter(company => company.pivot.role==="ROLE_ADMIN");
                this.setState({companies: companies});
            }).catch(err => {
                if(err.response != null && err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }) 
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleOnSearch(value){
        this.setState({searchText: value});
    }

    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    render(){
        const companiesToDisplay = this.state.companies.filter(company => {
            return company.company_name.includes(this.state.searchText.toLowerCase());
        });
        
        //Vérification de la connexion de l'utilisateur
        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar actual_tab={"Company"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Title level={1}>Mes entreprises</Title>
                    </Header>
                    <Content className="app-content">                    
                        <Row className="toolbar" justify="space-between">
                            <Col span={4}>
                                <Search placeholder="Rechercher une entreprise" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton/>
                            </Col>
                        </Row>
                        <Row>
                            <List
                                grid={{
                                    gutter: 32,
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                    lg: 4,
                                    xl: 6,
                                    xxl: 6,
                                }}
                                pagination={{
                                    pageSize: 12,
                                    hideOnSinglePage:true,
                                }}
                                dataSource={companiesToDisplay}
                                renderItem={item => (
                                    <CompanyBigCard key={item.id} data={item}/>
                                    )
                                }
                                locale={{emptyText:<Empty description="Aucune entreprise trouvée" image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}
                            />                                   
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.profile,
        trainings: state.trainings.trainings,
        trainers: state.trainers.trainers,
        users: state.users.users
    };
};

export default connect(mapStateToProps)(Companies);