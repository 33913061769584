//Import des composants utiles
import React, { useState } from 'react';
import API from '../api';

import { Button, Typography, Modal, Form, DatePicker, Select, Input, Row } from "antd";
import { CheckNationalNumber } from '../utils';
import { EditOutlined } from '@ant-design/icons';
const { Text } = Typography;
import moment from 'moment';

export const ModalEmployee = ({employee, refresh_handler}) => {
    const [visible, setVisible] = useState(false);
    const [error, setError] = useState();

    //Conversion des dates pour le Picker
    employee.birth_date = employee.birth_date!==null ? moment(employee.birth_date!==null?employee.birth_date:new Date("1995-12-17")) : null;
    employee.adr_validity = employee.adr_validity!==null ? moment(employee.adr_validity!==null?employee.adr_validity:undefined) : null;
    employee.driver_license_validity = employee.driver_license_validity!==null ? moment(employee.driver_license_validity!==null?employee.driver_license_validity:undefined) : null;
    employee.medical_selection = employee.medical_selection!==null? moment(employee.medical_selection!==null?employee.medical_selection:undefined) : null;

    //Prise en charge de la validation
    const handleOk = (values) => {
        API.put(`api/users/${employee.id}`, values)
        .then(() => {
            refresh_handler();
            setVisible(false);
            setError();
        })
        .catch((err) => {
            setError("Erreur serveur réessayer !");
        });
    }

    return (
        <>
            <Button onClick={() => setVisible(true)} type="primary" icon={<EditOutlined />}>
                Modifier
            </Button>
            <Modal
                title="Détails de l'employé"
                visible={visible}
                onCancel={() => setVisible(false)}
                footer={[
                    <Button key="back" type="text" onClick={() => setVisible(false)}>
                        Annuler
                    </Button>,
                    <Button form="editClient" type="primary" key="submit" htmlType="submit">
                        Modifier
                    </Button>
                ]}
                destroyOnClose
            >
                <Form id="editClient" layout="vertical" onFinish={(values) => handleOk(values)} initialValues={employee}>
                    <Form.Item name="first_name" label="Prénom">
                        <Input id={"first_name"} placeholder="Entrez un prénom" />
                    </Form.Item>
                    <Form.Item name="last_name" label="Nom">
                        <Input id={"last_name"} placeholder="Entrez un nom" />
                    </Form.Item>
                    <Form.Item name="national_number" label="Numéro de registre national" rules={[{ required: true, message: "Veuillez entrer un numéro de registre national" }]}>
                        <Input id={"national_number"} placeholder="Entrez un numéro de registre national" />
                    </Form.Item>
                    <Form.Item name="email" label="Email">
                        <Input id={"email"} placeholder="Entrez une adresse mail" />
                    </Form.Item>
                    <Form.Item name="mobile_phone_number" label="Numéro de téléphone">
                        <Input id={"mobile_phone_number"} placeholder="Entrez un numéro de téléphone" />
                    </Form.Item>
                    <Form.Item name="birth_city" label="Lieu de naissance">
                        <Input id={"birth_city"} placeholder="Entrez un lieu de naissance" />
                    </Form.Item>
                    <Form.Item name="birth_country" label="Nationalité">
                        <Input id={"birth_country"} placeholder="Entrez une nationalité" />
                    </Form.Item>
                    <Form.Item name="birth_date" label="Date de naissance">
                        <DatePicker id={"birth_date"} format={"DD/MM/YYYY"} />
                    </Form.Item>
                    <Form.Item name="street" label="Rue">
                        <Input id={"street"} placeholder="Entrez la rue" />
                    </Form.Item>
                    <Form.Item name="street_number" label="Numéro">
                        <Input id={"street_number"} placeholder="Entrez le numéro" />
                    </Form.Item>
                    <Form.Item name="street_number_box" label="Numéro de boite">
                        <Input id={"street_box"} placeholder="Entrez le numéro de boite" />
                    </Form.Item>
                    <Form.Item name="locality" label="Ville">
                        <Input id={"locality"} placeholder="Entrez la ville" />
                    </Form.Item>
                    <Form.Item name="zip_code" label="Code postal">
                        <Input id={"zip_code"} placeholder="Entrez le code postal" />
                    </Form.Item>
                    <Form.Item name="country" label="Pays">
                        <Input id={"country"} placeholder="Entrer votre pays" />
                    </Form.Item>
                    <Form.Item name="medical_selection" label="Date de sélection médicale">
                        <DatePicker id={"medical_selection"} format={"DD/MM/YYYY"}/>
                    </Form.Item>
                    <Form.Item name="adr_certificate_number" label="Numéro du permis du certificats ADR">     
                        <Input id={"adr_certificate_number"} placeholder="Entrez le numéro du certificats ADR" />
                    </Form.Item>
                    <Form.Item name="adr_validity" label="Validité du permis ADR">
                        <DatePicker id={"adr_validity"} format={"DD/MM/YYYY"} />
                    </Form.Item>
                    <Form.Item name="adr_category" label="Catégorie du permis ADR">
                        <Select
                          initialvalues={employee.adr_category}
                          placeholder="Sélectionnez une catégorie de permis ADR"
                          style={{ width: 200 }}
                        >
                            <Select.Option key={1} value={"colis"}>Colis</Select.Option>
                            <Select.Option key={2} value={"citerne"}>Citerne</Select.Option>
                            <Select.Option key={3} value={"carburant liquide"}>Carburant liquide</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="studies_degree" label="Niveau d'étude">
                        <Select
                          initialvalues={employee.studies_degree}
                          placeholder="Sélectionnez un niveau d'étude"
                          style={{ width: 200 }}
                        >
                            <Select.Option key={1} value={"PRIMAIRE"}>Primaire</Select.Option>
                            <Select.Option key={2} value={"SECONDAIRE_INF"}>Secondaire Inférieur</Select.Option>
                            <Select.Option key={3} value={"SECONDAIRE_SUP"}>Secondaire Supérieur</Select.Option>
                            <Select.Option key={4} value={"SUP_COURT"}>Supérieur type Court</Select.Option>
                            <Select.Option key={5} value={"SUP_LONG"}>Supérieur type Long</Select.Option>
                            <Select.Option key={6} value={"UNIVERSITAIRE"}>Universitaire</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="driving_experience" label="Expérience">
                        <Select
                          initialvalues={employee.driving_experience}
                          placeholder="Sélectionnez une expérience"
                          style={{ width: 200 }}
                        >
                           
                            <Select.Option key={1} value={"0-5"}>0-5 ans</Select.Option>
                            <Select.Option key={2} value={"5-9"}>5-9 ans</Select.Option>
                            <Select.Option key={3} value={"10-14"}>10-14 ans</Select.Option>
                            <Select.Option key={4} value={"15-19"}>15-19 ans</Select.Option>
                            <Select.Option key={5} value={"20-plus"}>20 ans et plus</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="driver_license_number" label="Numéro du permis de conduire" >     
                        <Input id={"driver_license_number"} placeholder="Entrez le numéro du permis de conduire" />
                    </Form.Item>
                    <Form.Item name="driver_license_validity" label="Validité du permis de conduire">
                        <DatePicker id={"driver_license_validity"} format={"DD/MM/YYYY"} />
                    </Form.Item>
                    <Form.Item name="driving_license_categories" label="Catégorie du permis de conduire">
                        <Select
                          initialvalues={employee.driving_license_categories}
                          placeholder="Sélectionnez une catégorie de permis de conduire"
                          style={{ width: 200 }}
                        >
                            <Select.Option key={1} value={"c1"}>C1/C</Select.Option>
                            <Select.Option key={2} value={"d1"}>D1/D</Select.Option>
                            <Select.Option key={3} value={"be"}>BE</Select.Option>
                            <Select.Option key={4} value={"ce"}>C(1)E</Select.Option>
                            <Select.Option key={5} value={"de"}>D(1)E</Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
                {error != "" &&
                    <Row justify="center">
                        <Text type="danger">{error}</Text>
                    </Row>
                }  
            </Modal>
        </>
    )
}