/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

//require('./bootstrap');
require('aos');
require('@fortawesome/fontawesome-free/js/all.js');

//Import des composants de base
import React from "react";
import ReactDOM from 'react-dom';
import { HashRouter, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './Store/Store';

import moment from 'moment';
//Import du Scss Global
//import 'antd/dist/antd.css'
//import '../sass/ant.scss';
import '../sass/app.scss';

//Import des composants crées
import ProfilePage from './Pages/Profile';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Confirmation from './Pages/Confirmation';
import PasswordReset from './Pages/PasswordReset';

//Admin ---
import AdminTrainingPage from './Pages/Admin/Training';
import AdminCompany from './Pages/Admin/Company';
import AdminTrainings from './Pages/Admin/Trainings';
import AdminClients from './Pages/Admin/Clients';
import AdminTrainers from './Pages/Admin/Trainers';
import AdminParameters from './Pages/Admin/Parameters';
import AdminDashboard from './Pages/Admin/Dashboard';
import AdminCertificates from './Pages/Admin/Certificates';
import AdminPlaces from "./Pages/Admin/Places";
import AdminPlace from "./Pages/Admin/Place";
import AdminUser from "./Pages/Admin/User";
import AdminTrainer from "./Pages/Admin/Trainer";

//Trainer --- 
import TrainerDashboard from "./Pages/Trainer/Dashboard";
import TrainerTraining from "./Pages/Trainer/Training";

//Entreprise ---
import EntrepriseTrainingsPage from './Pages/Entreprise/Trainings';
import EntrepriseTrainingPage from './Pages/Entreprise/Training';
import UserCompany from './Pages/Entreprise/Company';

//User ---
import UserTraining from './Pages/User/Training';
import UserDashboard from './Pages/User/Dashboard';
import UserTrainings from './Pages/User/Trainings';
import UserCompanies from './Pages/User/Companies';
import { ConfigProvider } from "antd";
import locale from 'antd/es/locale/fr_BE';

require ('./Pages/Actualities')
require ('./Pages/HomeTrainings')
//Language configuratiuon
moment.locale('fr')
//Base de l'application
function App() {
    return (
        <ConfigProvider locale={locale}>
            <Provider store={store}>
            <div className="App" style={{height:'100vh', width:'100vw'}}> 
                <HashRouter>               
                <Switch>
                    <Route exact path="/" component={Login} />
                    <Route exact path="/register" component={Register} />
                    <Route path="/profile" component={ProfilePage} />
                    <Route path="/verify-email" component={Confirmation} />
                    <Route path="/passwordreset" component={PasswordReset} />

                    {/* User - Driver Company - Company */}
                    <Route path="/dashboard" component={UserDashboard} />

                    {/* User - Driver Company */}
                    <Route path="/utraining" component={UserTraining} />
                    <Route path="/utrainings" component={UserTrainings} />
                    <Route path="/companies" component={UserCompanies} />
                    <Route path="/company" component={UserCompany} />

                    {/* Company */}
                    <Route path="/centreprise" component={AdminCompany} />
                    <Route path="/ctrainings" component={EntrepriseTrainingsPage} />
                    <Route path="/ctraining" component={EntrepriseTrainingPage} />

                    {/* Admin */}
                    <Route path="/atraining" component={AdminTrainingPage} />
                    <Route path="/atrainers" component={AdminTrainers} />
                    <Route path="/atrainings" component={AdminTrainings} />
                    <Route path="/aclients" component={AdminClients} />
                    <Route path="/aenterprise" component={AdminCompany} />
                    <Route path="/parameters" component={AdminParameters} />
                    <Route path="/adashboard" component={AdminDashboard} />
                    <Route path="/acertificates" component={AdminCertificates} />
                    <Route path="/aplaces" component={AdminPlaces} />
                    <Route path="/aplace" component={AdminPlace} />
                    <Route path="/auser" component={AdminUser} />
                    <Route path="/atrainer" component={AdminTrainer} />

                    {/* Trainer */}
                    <Route path="/ttraining" component={TrainerTraining} />
                    <Route path="/tdashboard" component={TrainerDashboard} />
                
                </Switch>
                </HashRouter>               
            </div>
        </Provider>
        </ConfigProvider>
        
    );
}

ReactDOM.render(<App />, document.getElementById('root'));