//Import des composants utiles
import React from 'react';
import API from '../../api';
import { ActionCreators } from '../../Store/Action'
import store from '../../Store/Store';
import Navbar from '../../components/Navbar';
import { AddANewCertificate, EditCertificate, RemoveCertificate  } from '../../components/Modal'


//Import des composants de design
import { Typography, Layout, Input, Row, Col, List, Empty, Card } from 'antd';
const { Title, Text } = Typography;
const { Header, Sider, Content } = Layout;
const { Search } = Input;

const { Meta } = Card;

export const CertificateBigCard = ({certificate, refresh_handler}) => {
    let expiration = "Pas d'expiration"
    let validity=certificate.validity.substring(1);
    let years=0;
    let month=0;
    if(validity.includes('Y') && !validity.includes('M')){
        years=validity.split('Y')[0];
        expiration = "Validité de "+years+" ans";
    }
    if(validity.includes('M') && !validity.includes('Y')){
        month=validity.split('M')[0];
        expiration = "Validité de "+month+" mois";
    }
    if(validity.includes('M') && validity.includes('Y')){
        years=validity.split('Y')[0];
        month=(validity.split('M')[0]).split('Y')[1];
        expiration = "Validité de "+years+" ans et "+month+" mois";
    }

    return (
        <Card
            actions={[<RemoveCertificate refresh_handler={refresh_handler} certificate={certificate}/>,<EditCertificate refresh_handler={refresh_handler} certificate={certificate}/>]}
        >
            <Meta title={certificate.name} description={expiration}/>
            <Text type="secondary">{certificate.description}</Text>

        </Card>
    );
}

//Page de listing des formations
class Certificates extends React.Component{
    constructor(props){
        super(props)

        //Récupération des informations dans le store
        this.state = {
            user: store.getState().user,
            certificates: [],
            searchText: ''
        }
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
        this.refreshCertificates = this.refreshCertificates.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    refreshCertificates(){
        if(this.state.user.isLogged){
            API.get('api/certificates')
            .then(response => {
                this.setState({
                    certificates: response.data
                });
            }).catch(err => {
                if(err.response != null && err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }) 
        }
    }

    componentDidMount(){
        this.refreshCertificates();
    }

    componentWillUnmount(){
        
    }

    onSearch(value) {
        this.setState({searchText: value});
    }
    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }
    render(){
        //Filtrage des certificats
        const certificatesToDisplay = this.state.certificates.filter(certificate => {
            return certificate.name.toLowerCase().includes(this.state.searchText.toLocaleLowerCase())
        });

        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar actual_tab={"Certificate"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Title level={1}>Gérer les certificats</Title>
                    </Header>
                    <Content className="app-content">
                        <Row className="toolbar" justify="space-between">
                            <Col span={4}>
                                <Search placeholder="Rechercher un élément" onChange={this.handleOnChangeSearch} onSearch={this.onSearch} style={{ width: 250, borderRadius:50 }} enterButton/>
                            </Col>
                            <Col>
                                <AddANewCertificate refresh_handler={this.refreshCertificates} />
                            </Col>
                        </Row>   
                        <Row>
                            {/* Certificates */}
                            <List
                                grid={{
                                    gutter: 32,
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                    lg: 4,
                                    xl: 4,
                                    xxl: 6,
                                }}
                                pagination={{
                                    pageSize: 12,
                                    hideOnSinglePage:true,
                                }}
                                dataSource={certificatesToDisplay}
                                renderItem={item => (
                                    <CertificateBigCard id={item.id} key={item.id} certificate={item} refresh_handler={this.refreshCertificates.bind(this)}/>
                                    )
                                }
                                locale={{emptyText:<Empty description="Aucun certificat trouvé" image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}
                            />                                   
                        </Row>                 
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default Certificates;