import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import API from '../api';
import {Radio, List} from 'antd';

import AOS from 'aos';

//Page des actualités
export default class HomeTrainings extends Component {

    constructor(props){
        super(props);

        this.state = {
            trainings:[],
            places:[],
            occurences:[],
            selected_training:-1,
            radio_default:0,
            training_cities:[],
            training_infos:{name:"",description:""},
        };

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    async componentDidMount(){
        this._isMounted = true;
        var start_date = new Date().toUTCString();

        Promise.all([API.get(`/api/trainings`), API.get(`/api/training-occurences?start_date=${start_date}`), API.get(`/api/places`)]).then(response => {
            const trainings = response[0].data;
            const occurences = response[1].data;
            const places = response[2].data;

            if(this._isMounted){
                if(trainings.length>0){
                    this.setState({
                        trainings: trainings,
                        selected_training: trainings[0].id,
                        occurences: occurences
                    })
                }

                this.setState({
                    places: places
                })

                this.handleSelectedTrainingChange(this.state.selected_training);
            }
        })
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleSelectedTrainingChange(newSelectedTraining,e){

        var places=[];

        this.state.occurences.forEach(function(occurence){
            if(occurence.training_id == newSelectedTraining){
                if(places.indexOf(occurence.place_id) == -1){
                    this.state.places.forEach(function(place){
                        if(place.id == occurence.place_id){
                            places.push({label:place.name,value:place.id});                          
                        }
                    }.bind(this))
                }
            }
        }.bind(this))

        if(this.state.trainings.length>0 && newSelectedTraining != -1){
            this.setState({
                training_infos:{name:this.state.trainings.find(training => training.id === newSelectedTraining).name,description:this.state.trainings.find(training => training.id === newSelectedTraining).description},
            });
        }

        if(places.length>0){
            this.setState({
                training_cities: [...new Set(places.map(p => p.value))].map(id => {
                    return places.find(pl => pl.value === id);
                }),
                radio_default:places[0].value,
            });
        }else{
            this.setState({
                training_cities: [],
                radio_default:0,
            });
        }

        this.setState({
            selected_training:newSelectedTraining,
        });
    }

    onChange(e){
        this.setState({
            radio_default: e.target.value,
        });
    };

    render(){
        AOS.init();

        var li_occurence_trainings = "";

        if(this.state.occurences.length>0){
            li_occurence_trainings = this.state.occurences.map(function(occurence, index){

                if(occurence.training_id == this.state.selected_training){
                    if(occurence.place_id == this.state.radio_default){
                        var durations = occurence.durations.sort((a, b) => new Date(a.start_date) - new Date(b.start_date)).map(duration => {

                            var new_start_date = new Date(duration.start_date);
                            var new_end_date = new Date(duration.end_date);

                            var month_list = ['janvier','février','mars','avril','mai','juin','juillet','aout','septembre','octobre','novembre','décembre'];

                            var day = new_start_date.getUTCDate();
                            var month = new_start_date.getUTCMonth();
                            var year = new_start_date.getUTCFullYear();
                            var hour1 = new_start_date.getUTCHours();
                            var minutes1 = new_start_date.getUTCMinutes() < 10 ? '0' + new_start_date.getUTCMinutes() : new_start_date.getUTCMinutes();
                            var hour2 = new_end_date.getUTCHours();
                            var minutes2 = new_end_date.getUTCMinutes() < 10 ? '0' + new_end_date.getUTCMinutes() : new_end_date.getUTCMinutes();

                            return <p key={duration.id}>{day+" "+month_list[month]+" "+year+" - "+hour1+"h"+minutes1+ " - " +hour2+"h"+minutes2}</p>
                        })

                        return <li key={occurence.id} className="occurence_trainings">
                                <div>
                                    <h6>{this.state.places.find(place => place.id === this.state.radio_default).name}</h6>
                                    {durations}
                                </div>
                                <div>
                                    <h6>{occurence.remaining_seats}/{occurence.available_seats}</h6>
                                    <p>Places disponibles</p>
                                </div>
                                </li>
                    }
                }  
                }.bind(this)
            );
        }  
        if(this.state.trainings.length>0 && this.state.training_infos.name != ""){     
            return (
                
                <article>
                    <div className="trainings_list" data-aos="fade-up" data-aos-delay="100">
                        <List
                            itemLayout="vertical"
                            size="large"
                            pagination={{
                                pageSize: 4,
                            }}
                            dataSource={this.state.trainings}
                            renderItem={item => (
                            <List.Item
                                key={item.id}
                                onClick={this.handleSelectedTrainingChange.bind(this,item.id)}
                                className={this.state.selected_training==item.id?"selected":""}
                            >
                                <h5>{item.name}</h5><h6 className="orange"></h6>
                            </List.Item>
                            )}
                        />
                    </div>
                    <div className="training_info">
                        <div className="training_details" data-aos="fade-up">
                            <h5>{this.state.training_infos.name}</h5>
                            <p>{this.state.training_infos.description}</p>
                        </div>
                        <div className="next_trainings" data-aos="fade-up">
                            <h5>Prochaines formations</h5>
                            {this.state.training_cities.length>0?<>
                                <Radio.Group
                                options={this.state.training_cities}
                                onChange={this.onChange.bind(this)}
                                value={this.state.radio_default}
                                optionType="button"
                                buttonStyle="solid"
                                />
                                
                                <ul data-aos="fade-up" data-aos-delay="100">
                                    {li_occurence_trainings}
                                </ul>
                            </>:
                            <>
                                Aucune Formations
                            </>}
                        </div>
                    </div>
                </article>
            );
        }else{
            return (
                <article><h3>Nous n'avons pas de formation prochainement.</h3></article>

            );
        }
    }
}

if (document.getElementById('home_trainings')) {
    ReactDOM.render(<HomeTrainings />, document.getElementById('home_trainings'));
}