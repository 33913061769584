//Import des composants utiles
import React from 'react';
import API from '../../api';
import { ActionCreators } from '../../Store/Action'
import store from '../../Store/Store';
import { connect } from 'react-redux';
import Navbar from '../../components/Navbar';
import axios from 'axios';

//Import des composants de design
import { Typography, Layout, Card, Avatar, Input, Row, Col, List, Empty } from 'antd';
import { GoToButton } from '../../components/Component'
import { AddANewTrainer } from '../../components/Modal'
const { Title } = Typography;
const { Header, Sider, Content } = Layout;
const { Search } = Input;
const { Meta } = Card;

//Carte des détails de la formation
export function TrainerBigCard(props){
    return (
        <Card
            cover={<Avatar src={props.data.avatar_url} size={96}/>}
            actions={[<GoToButton id="editbtn" route="/atrainer" element_id={props.data.id}/>  ]}
        >
            <Meta title={props.data.first_name} description={props.data.last_name} />
        </Card>
    );
}

//Page de listing des formations
class Trainers extends React.Component{    
    constructor(props){
        super(props)

        //Récupération des informations dans le store
        this.state = {
            trainers: [],
            searchText: "",
            visible:false
        }

        //Génération d'un token d'arrêt des appels
        this.source = axios.CancelToken.source();
        this.toggleAddTrainerModal = this.toggleAddTrainerModal.bind(this)
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }
    toggleAddTrainerModal(){        
        this.setState({visible:!this.state.visible});
    }
    componentDidMount(){
        this._isMounted = true;
        let config = { cancelToken: this.source.token}

        this.loadTrainersList(config);
    }

    componentWillUnmount(){
        this._isMounted = false;
        this.source.cancel("Unmounted Component");
    }

    //Chargement de la liste des formateurs
    loadTrainersList(config){
        API.get('api/users?role=ROLE_TRAINER', config)
        .then((res) => {
            if(this._isMounted){
                this.setState({trainers: res.data});
            }
        }).catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        }) 
    }

    //Recherche en direct
    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    //Recherche Hit Enter
    handleOnSearch(value){
        this.setState({searchText: value});
    }

    render(){     
        //Filtrage des formateurs
        const trainersToDisplay = this.state.trainers.filter(trainer => {
            return trainer.first_name.toLowerCase().includes(this.state.searchText.toLowerCase()) || (trainer.last_name).toLowerCase().includes(this.state.searchText.toLowerCase()) || (trainer.first_name + " " + trainer.last_name).toLowerCase().includes(this.state.searchText.toLowerCase());
        });
        
        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar actual_tab={"Trainers"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Title level={1}>Gérer les formateurs</Title>
                    </Header>
                    <Content className="app-content">  
                        <Row className="toolbar" justify="space-between">
                            <Col span={4}>
                                <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton/>
                            </Col>
                            <Col>
                                <AddANewTrainer refresh_handler={this.loadTrainersList.bind(this)}/>
                            </Col>
                        </Row>                      
                        <Row>
                            <List
                                grid={{
                                    gutter: 32,
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                    lg: 4,
                                    xl: 4,
                                    xxl: 6,
                                }}
                                pagination={{
                                    pageSize: 12,
                                    hideOnSinglePage:true,
                                }}
                                dataSource={trainersToDisplay}
                                renderItem={item => (
                                    <TrainerBigCard key={item.id} data={item} />
                                    )
                                }
                                locale={{emptyText:<Empty description="Aucun formateur existant" image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}
                            />  
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );

    }
}

const mapStateToProps = state => {
    return {
        user: state.user.profile,
        trainings: state.trainings.trainings,
        trainers: state.trainers.trainers,
        users: state.users.users
    };
};

export default connect(mapStateToProps)(Trainers);