//Import des composants utiles
import React from 'react';
import API from '../../api';
import store from '../../Store/Store';
import { connect } from 'react-redux';
import Navbar from '../../components/Navbar';
import axios from 'axios';
import Moment from 'moment';

//Import des composants de design
import { Card, Layout,  Button, Row, Col, Space, List, Empty } from 'antd';
import { TrainingDetailsCard } from '../../components/LeftPanel';
import { SeancesExerptDetail } from '../../components/MiddlePanel';
import { Seances, TrainingCalendar } from '../../components/RightPanel'
import { GoBackTitle, Loading } from '../../components/Component'
import { Redirect } from 'react-router';
const { Header, Sider, Content } = Layout;
const { Meta } = Card;
import { FileExclamationOutlined } from '@ant-design/icons';


//Page de détail d'une formation
class TrainingPage extends React.Component{
    constructor(props){
        super(props)

        //Récupération de la date actuelle
        let date;
        let occurence_id;
        if(props.location.state != undefined){
            const pathDate = new Date(props.location.state.refDate.start_date);
            date = pathDate.getFullYear()+'-'+((pathDate.getMonth()+1 < 10)?'0'+(pathDate.getMonth()+1):(pathDate.getMonth()+1))+'-'+((pathDate.getDate() < 10)?'0'+pathDate.getDate() : pathDate.getDate())
            occurence_id = props.location.state.refDate.id;
        }else{
            const today = new Date();
            date = today.getFullYear()+'-'+((today.getMonth()+1 < 10)?'0'+(today.getMonth()+1):(today.getMonth()+1))+'-'+((today.getDate() < 10)?'0'+today.getDate() : today.getDate())
            occurence_id = undefined;
        }

        //Récupération des informations dans le store
        this.state = {
            user: store.getState().user,
            training: {
                id: undefined,
                name: "",
                price: 0,
                description: "",
                files: [],
                occurences: []   ,
                training_type_code: "",
                number_training_vouchers: 0,             
            },
            actual_date: date,
            selected_date: date,
            selected_occurence_id: occurence_id,
            current: "seances",
            places: [],
        }

        //Génération d'un token d'arrêt des appels
        this.source = axios.CancelToken.source();

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = true;
        this.loadTrainingOccurences = this.loadTrainingOccurences.bind(this);
        this.loadTrainingOccurenceDetails = this.loadTrainingOccurenceDetails.bind(this);
    }

    //Prise en charge de la séléction de l'occurence
    handleOccurenceSelect(event){
        this.setState({selected_occurence_id: event.target.value});
        this.loadTrainingOccurenceDetails(event.target.value);
    }

    //Prise en charge de la séléction de la date
    handleDateSelect(value){
        // Load the list of selected occurence with this date
        const occurences_date_selected = this.state.training.occurences.filter(occ => 
            occ.durations.filter(function(e){return e['start_date'].split("T")[0] == value.format('YYYY-MM-DD')}).length > 0
        );
        if (occurences_date_selected.length > 0) {
            this.setState({selected_date: value.format('YYYY-MM-DD'), selected_occurence_id: occurences_date_selected[0].id});
            this.loadTrainingOccurenceDetails(occurences_date_selected[0].id);
        } else {
            this.setState({selected_date: value.format('YYYY-MM-DD'), selected_occurence_id: undefined});
        }
    }

    refreshSelectedDate(){
        if(this._isMounted){
            const selected_date = this.state.selected_date;

            const train = [];

            this.state.training.occ.forEach(training => {
                if(training.date.filter(function(e){return e['start_date'].split("T")[0] == selected_date}).length > 0){
                    train.push(training)
                }
            });

            this.setState({selected_trainings: train})
        }
    }

    //Prise en charge du changement de menu
    handleClick(event) {
        this.setState({ current: event.key })
    };

    //Récupération des occurences de la formation
    componentDidMount(){
        //Récupérer les informations de l'occurence en question
        this.loadTrainingDetail();
        this.loadTrainingOccurences();
    }

    //Chargement des détails de la formation
    loadTrainingDetail(){
        API.get('api/trainings/'+this.state.user.selected)
        .then(train_data => {    
            this.setState({training: {
                    ...this.state.training,
                    id: train_data.data.id,
                    name: train_data.data.name,
                    price: train_data.data.price,
                    description: train_data.data.description,
                    training_type_code: train_data.data.training_type_code,
                }
            });
        }).catch(err => { 
            this.source.cancel("Request Error");
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        });

        API.get('api/trainings/'+this.state.user.selected+'/files').then(res => {
            this.setState({training: {
                ...this.state.training,
                files: res.data,
            }});
        }).catch(err => { 
            this.source.cancel("Request Error");
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        }); 
    }

    loadTrainingOccurences() {
        API.get('api/trainings/'+this.state.user.selected+"/training-occurences").then(res => {
            const trainingOccurences = res.data;

            Promise.all([...new Set(trainingOccurences.map(trOcc => trOcc.place_id))].map(placeId => API.get('api/places/'+ placeId))).then(res => {
                const places = res.map(o => o.data);
                const occurences = trainingOccurences.map(occ => {
                    occ.place = places.find(place => place.id == occ.place_id);
                    occ.durations.map(date => {
                        if(date.start_date.split("T")[0].split("-")[0]===new Date().getFullYear()){
                            this.setState({selected_occurence_id: occ.id});
                            this.loadTrainingOccurenceDetails(occ.id);
                        }
                    })
                    return occ;
                });
                this.setState({training: {
                    ...this.state.training,
                    occurences: occurences,
                }}, () => {
                    if(this.props.location.state != undefined){
                        this.loadTrainingOccurenceDetails(this.props.location.state.refDate.id);
                    }else{
                        this.handleDateSelect(Moment.utc(new Moment.utc().format('YYYY-MM-DD')))
                    } 
                });
            });
        });

        API.get('api/places').then(res => {
            this.setState({
                places: res.data
            });
        });
    }

    loadTrainingOccurenceDetails(trainingOccurenceId = this.state.selected_occurence_id) {
        API.get('api/training-occurences/'+trainingOccurenceId+'/users').then(res => {
            const trainers = res.data.filter(user => user.pivot.role == "ROLE_TRAINER");
            const students = res.data.filter(user => user.pivot.role == "ROLE_STUDENT");

            const occurences = this.state.training.occurences.slice();
            const index = occurences.findIndex(o => o.id == trainingOccurenceId);
            occurences[index].trainers = trainers;
            occurences[index].students = students;

            this.setState({training: {...this.state.training, occurences: occurences}});
        });
    }

    //Prise en charge de l'enregistrement des utilisateurs à la formation
    handleRegister(userId){
        API.put('/api/training-occurences/'+this.state.selected_occurence_id+'/users/'+userId,{
            role: 'ROLE_STUDENT'
        }).then((res) => {
            this.loadTrainingOccurences();
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
        
    }

    //Prise en charge de l'enregistrement des utilisateurs à la formation
    handleUnregister(userId){
        API.delete('/api/training-occurences/'+this.state.selected_occurence_id+'/users/'+userId)
        .then((res) => {
            this.loadTrainingOccurences();
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })  
    }

    componentWillUnmount(){
        this.source.cancel();
    }

    render(){
        const selected_date = this.state.selected_date;
        const occurences_date_selected = this.state.training.occurences.filter(occ => 
            occ.durations.filter(function(e){return e['start_date'].split("T")[0] == selected_date}).length > 0
        );
        
        let selected_occurence;
        if (this.state.selected_occurence_id != undefined) {
            selected_occurence = this.state.training.occurences.find(occ => occ.id == this.state.selected_occurence_id);         
        }

        let isRegister = selected_occurence!==undefined&&selected_occurence.students!==undefined?selected_occurence.students.find((student) => student.id == this.state.user.profile.id)!==undefined:false

        let freelanceButton = <Button type="primary" onClick={this.handleRegister.bind(this,store.getState().user.profile.id)}>M'inscrire à la séance</Button>;
        if(isRegister && selected_occurence.students.find((student) => student.id == this.state.user.profile.id).pivot.company_id !== null){
            freelanceButton=<></>
        }else if(isRegister){
            freelanceButton=<Button type="primary" onClick={this.handleUnregister.bind(this,store.getState().user.profile.id)} danger>Me désinscrire de la séance</Button>;
        }

        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar actual_tab={"Trainings"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">                      
                    <Header>
                        <GoBackTitle route="/dashboard" title="Revenir aux formations"/>
                    </Header>
                    {this.state.user.selected && this.state.training.id != undefined ?
                    <Content className="app-content">
                        <Row gutter={[48, 48]}>
                            {/* LeftPanel */}
                            <Col span={6}>
                                <Space size={[22, 8]} direction="vertical">
                                    <TrainingDetailsCard training={this.state.training} refresh_handler={this.loadTrainingDetail.bind(this)} isAdmin={false}/>  
                                    <Card>
                                        <Meta title="Fichiers"/>
                                        <List
                                            size="small"
                                            className="cardList"
                                            dataSource={this.state.training.files.filter(file => file.type !== "FILE_ATTENDANCE_RECORD" && file.type !== "FILE_INVOICE")}
                                            renderItem={item => (<>                                  
                                                <FileAction delete_handler={this.handleFileChange.bind(this)} key={item.id} data={item} />
                                                </>
                                            )}
                                            pagination={{
                                                pageSize: 3,
                                                hideOnSinglePage:true,
                                                simple:true,
                                            }}
                                            locale={{emptyText:<Empty description="Aucun document trouvé" image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                                        />
                                    </Card> 
                                </Space>                          
                            </Col>
                                
                            <Col span={10}>
                                
                                {selected_occurence != undefined ?
                                    <Space size={[50, 8]} direction="vertical">
                                        <SeancesExerptDetail places={this.state.places} placeChange_handler={this.updatePlaceTrainingOccurence} training={selected_occurence} />               
                                        {selected_occurence != undefined &&
                                            <>{freelanceButton}</>
                                        }
                                    </Space>

                                    :<Empty
                                                image={<FileExclamationOutlined/>}
                                                description={
                                                <span>
                                                    Pas de séance prévue ce jour
                                                </span>
                                                }
                                            >
                                            </Empty>
                                    }
                            </Col>

                            <Col span={8}>
                                <Space size={[22, 8]} direction="vertical">
                                    {/* Calendar */}
                                    <Card>
                                        <TrainingCalendar defaultValue={this.state.selected_date} trainings={this.state.training.occurences} handler={this.handleDateSelect.bind(this)}/>    
                                    </Card>          
                                    {selected_occurence != undefined &&
                                    <Card>
                                        <Meta title="Séances"/>
                                        {/* Seances  */}
                                        <Seances id={selected_occurence.id} delete_handler={this.deleteTrainingOccurence} training_id={this.state.user.selected} refresh_handler={this.loadTrainingOccurences} occurences={occurences_date_selected} selected_date={this.state.selected_date} handler={this.handleOccurenceSelect.bind(this)}/>               
                                    </Card>
                                    }
                                </Space>
                            </Col>
                        </Row>
                    </Content>
                    :<Loading />}
                </Layout>
            </Layout>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: state.user.profile,
        trainings: state.trainings.trainings,
        trainers: state.trainers.trainers,
        users: state.users.users
    };
};

export default connect(mapStateToProps)(TrainingPage);