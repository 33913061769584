//Import des composants utiles
import React from 'react';
import store from '../../Store/Store';
import API from '../../api';
import { connect } from 'react-redux';
import Navbar from '../../components/Navbar';
import axios from 'axios';
import moment from 'moment';

import { FundProjectionScreenOutlined } from '@ant-design/icons';

//Import des composants de design
import { Layout, Col, Row, List, Empty, Card, Space } from 'antd';
import { UserProfileCard } from '../../components/LeftPanel';
import { GoBackTitle, Loading } from '../../components/Component'
import { CalendarFullScreenFalse } from '../../components/RightPanel';
import Meta from 'antd/lib/card/Meta';
const { Header, Sider, Content } = Layout;

//Page profile de l'utilisateur
class Trainer extends React.Component{
    constructor(props){
        super(props)

        //Récupération des informations dans le store
        this.state = {
            user : store.getState().user,
            selected_user : null,
            trainings: [],
            myTrainings : [],
            myUser: null,
            dateSelected: moment(),
        }

        //Génération d'un token d'arrêt des appels
        this.source = axios.CancelToken.source();

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
        this.handle_calendar_date_select = this.handle_calendar_date_select.bind(this);
        this.refresh_trainer_panel=this.refresh_trainer_panel.bind(this);
    }
    
    async componentDidMount(){
        this._isMounted = true;
        let config = { cancelToken: this.source.token}

        if(store.getState().user.isLogged){
            API.get('/api/users/'+this.state.user.selected).then(response => {
                const user = response.data;

                API.get('/api/users/'+user.id+'/training-occurences').then(res => {
                    user.training_occurences = res.data;

                    const promises = [];

                    promises.push(Promise.all([...new Set(user.training_occurences.map((tr_occ) => tr_occ.training_id))].map(training_id => API.get('/api/trainings/'+training_id))).then(res => {
                        const trainings = res.map(o => o.data);
                        user.training_occurences.forEach(occ => {
                            occ.training = trainings.find(t => t.id === occ.training_id);
                        });
                    }));

                    promises.push(...user.training_occurences.map((occ) => {
                        return API.get('/api/training-occurences/'+occ.id+'/durations').then((res) => {
                            occ.durations = res.data;
                            occ.lastDurationDate = moment.max([].concat(...occ.durations.map((dur) => [moment(dur.start_date), moment(dur.end_date)]))).toISOString();
                        });
                    }));

                    Promise.all(promises).then(() =>{
                        this.setState({myUser: user});
                    });
                });
            });
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
        this.source.cancel("Unmounted Component");
    }

    handle_calendar_date_select(dateSelected) {
        this.setState({dateSelected: dateSelected});
    }

    refresh_trainer_panel(){
        API.get('/api/users/'+this.state.user.selected).then(response => {
            const user = response.data;
            
            this.setState({myUser: {...user,training_occurences:this.state.myUser.training_occurences}});
        });
    }

    render(){
        const dates = [];

        if (this.state.myUser != null)
            this.state.myUser.training_occurences.forEach((occ) => {
                occ.durations.forEach((duration) => {
                    dates.push(moment(duration.start_date));
                    dates.push(moment(duration.end_date));
                });
            });

        const trainings = [];
        if (this.state.myUser != null) {
            this.state.myUser.training_occurences.forEach((occ) => {
                trainings.push({
                    'id': occ.training_id,
                    'name': occ.training.name,
                    'date': occ.lastDurationDate,
                })
            });
        }

        const listTrainingOccurencesForADate = () => {
            if (this.state.myUser == null) return [];
            return this.state.myUser.training_occurences.filter((tr_occ) => (
                tr_occ.durations.some((duration) => 
                    moment(duration.start_date).isSame(this.state.dateSelected, 'day') || moment(duration.end_date).isSame(this.state.dateSelected, 'day'))
                )
            ); 
        }

        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar />
                </Sider>
                {this.state.myUser
                ?<Layout className="main-container">
                    <Header>
                        <GoBackTitle route="/atrainers" title="Revenir aux formateurs"/>
                    </Header>
                    <Content className="app-content">
                        <Row gutter={[48, 48]}>
                            <Col span={6}>
                                <UserProfileCard user={this.state.myUser} refresh_handler={this.refresh_trainer_panel}/>
                            </Col>

                            <Col span={8}>
                                <Card>
                                    <Meta title="Formations"/>
                                    <List className="cardList" size="small" dataSource={listTrainingOccurencesForADate()} renderItem={training_occurence => (
                                        <List.Item>
                                            <List.Item.Meta 
                                                avatar={<FundProjectionScreenOutlined />}
                                                title={training_occurence.training.name}
                                            />
                                        </List.Item>
                                    )}
                                    pagination={{
                                        pageSize: 4,
                                        hideOnSinglePage:true,
                                        simple:true,
                                        position:'top',
                                    }}
                                    locale={{emptyText:<Empty description="Aucune formation réalisée" image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}>
                                    </List>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Space size={[22, 8]} direction="vertical">
                                    <Card type="inner">
                                        <CalendarFullScreenFalse dates={dates} onSelect={this.handle_calendar_date_select}/>
                                    </Card>
                                   
                                </Space>
                            </Col>
                        </Row>
                    </Content>
                </Layout>:<Loading />}
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.profile,
        trainings: state.trainings.trainings,
        trainers: state.trainers.trainers,
        users: state.users.users
    };
};

export default connect(mapStateToProps)(Trainer);