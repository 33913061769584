//Import des composants utiles
import React from 'react';
import API from '../../api';
import store from '../../Store/Store';
import { connect } from 'react-redux';
import Navbar from '../../components/Navbar';
import axios from 'axios';
import Moment from 'moment';

//Import des composants de design
import { Card, Layout, Button, Row, Col, Space,List, Empty, Tabs  } from 'antd';
import { TrainingDetailsCard } from '../../components/LeftPanel';
import { SeancesExerptDetail } from '../../components/MiddlePanel';
import { Seances, TrainingCalendar, SmallEmployeeCard } from '../../components/RightPanel'
import { GoBackTitle, Loading } from '../../components/Component'
import { Redirect } from 'react-router';
const { Header, Sider, Content } = Layout;
const { Meta } = Card;
const { TabPane } = Tabs;
import { FileExclamationOutlined } from '@ant-design/icons';


//Page de détail d'une formation
class TrainingPage extends React.Component{
    constructor(props){
        super(props)

        //Récupération de la date actuel
        const today = new Date();
        const date = today.getFullYear()+'-'+((today.getMonth()+1 < 10)?'0'+(today.getMonth()+1):(today.getMonth()+1))+'-'+((today.getDate() < 10)?'0'+today.getDate() : today.getDate())

        //Récupération des informations dans le store
        this.state = {
            user: store.getState().user,
            training: {
                id: undefined,
                name: "",
                price: 0,
                description: "",
                occurences: [],
                training_type_code: "",                              
            },
            companies: [],
            selected_date: date,
            selected_occurence_id: undefined,
            redirect: false,
            current: "seances",
            places: [],
            selected_training_employees: {
                unregistered_employees: [],
                registered_employees: []
            },
        }

        //Génération d'un token d'arrêt des appels
        this.source = axios.CancelToken.source();

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = true;
        this.loadTrainingOccurences = this.loadTrainingOccurences.bind(this);
        this.loadTrainingOccurenceDetails = this.loadTrainingOccurenceDetails.bind(this);
    }

    //Prise en charge de la séléction de l'occurence
    handleOccurenceSelect(event){
        this.setState({selected_occurence_id: event.target.value});
        this.loadTrainingOccurenceDetails(event.target.value);
    }

    //Prise en charge de la séléction de la date
    handleDateSelect(value){
        // Load the list of selected occurence with this date
        const occurences_date_selected = this.state.training.occurences.filter(occ => 
            occ.durations.filter(function(e){return e['start_date'].split("T")[0] == value.format('YYYY-MM-DD')}).length > 0
        );
        if (occurences_date_selected.length > 0) {
            this.setState({selected_date: value.format('YYYY-MM-DD'), selected_occurence_id: occurences_date_selected[0].id});
            this.loadTrainingOccurenceDetails(occurences_date_selected[0].id);
        } else {
            this.setState({selected_date: value.format('YYYY-MM-DD'), selected_occurence_id: undefined});
        }
    }

    refreshSelectedDate(){
        if(this._isMounted){
            const selected_date = this.state.selected_date;

            const train = [];

            this.state.training.occ.forEach(training => {
                if(training.date.filter(function(e){return e['start_date'].split("T")[0] == selected_date}).length > 0){
                    train.push(training)
                }
            });

            this.setState({selected_trainings: train})
        }
    }

    //Prise en charge du changement de menu
    handleClick(event) {
        this.setState({ current: event.key })
    };

    //Récupération des occurences de la formation
    componentDidMount(){
        this.loadCompanies();

        //Récupérer les informations de l'occurence en question
        this.loadTrainingDetail();
        this.loadTrainingOccurences();
    }

    loadCompanies(){
        API.get('api/users/'+store.getState().user.profile.id+'/companies')
        .then(companies_data => {
            
            companies_data.data.forEach((company) => {
                API.get('api/companies/'+company.id+'/users')
                .then(employees_data => {
                    company['employees'] = employees_data.data                     
                })
            })

            if(this._isMounted){    
                this.setState({companies: companies_data.data})              
            }  
        }).catch(err => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Récupération des employés de l'entreprise
    loadCompanyEmployees(){
        API.get('api/users/'+store.getState().user.profile.id+'/employees')
        .then(employees_data => {
            employees_data.data.forEach(employee => {
                employee['selected'] = false;
            });

            if(this._isMounted){    
                this.setState({employees: employees_data.data})              
            }  
        }).catch(err => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Chargement des détails de la formation
    loadTrainingDetail(){
        API.get('api/trainings/'+this.state.user.selected)
        .then(train_data => {    
            this.setState({training: {
                    ...this.state.training,
                    id: train_data.data.id,
                    name: train_data.data.name,
                    price: train_data.data.price,
                    description: train_data.data.description,
                    training_type_code: train_data.data.training_type_code,
                }
            });
        }).catch(err => { 
            this.source.cancel("Request Error");
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        });
    }

    loadTrainingOccurences() {
        API.get('api/trainings/'+this.state.user.selected+"/training-occurences").then(res => {
            const trainingOccurences = res.data;

            Promise.all([...new Set(trainingOccurences.map(trOcc => trOcc.place_id))].map(placeId => API.get('api/places/'+ placeId))).then(res => {
                const places = res.map(o => o.data);
                const occurences = trainingOccurences.map(occ => {
                    occ.place = places.find(place => place.id == occ.place_id);
                    return occ;
                });
                this.setState({training: {
                    ...this.state.training,
                    occurences: occurences,
                }}, () => {
                    this.handleDateSelect(Moment.utc(new Moment.utc().format('YYYY-MM-DD')))
                });
            });

            if(this.state.selected_occurence_id != undefined){
                this.loadTrainingOccurenceDetails();
            }

            
        });

        API.get('api/places').then(res => {
            this.setState({
                places: res.data
            });
        });
    }

    loadTrainingOccurenceDetails(trainingOccurenceId = this.state.selected_occurence_id) {
        API.get('api/training-occurences/'+trainingOccurenceId+'/users').then(res => {
            const trainers = res.data.filter(user => user.pivot.role == "ROLE_TRAINER");
            const students = res.data.filter(user => user.pivot.role == "ROLE_STUDENT");

            const occurences = this.state.training.occurences.slice();
            const index = occurences.findIndex(o => o.id == trainingOccurenceId);
            occurences[index].trainers = trainers;
            occurences[index].students = students;

            this.setState({training: {...this.state.training, occurences: occurences}});
        });
    }

    handleBack(){
        if(this._isMounted)
            this.setState({redirect: true})
    }

    //Prise en charge de l'enregistrement des utilisateurs à la formation
    handleRegister(userId, company_id){
        API.put('/api/training-occurences/'+this.state.selected_occurence_id+'/users/'+userId,{
            company_id: company_id,
            role: 'ROLE_STUDENT'
        }).then((res) => {
            this.loadTrainingOccurences();
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
        
    }

    //Prise en charge de l'enregistrement des utilisateurs à la formation
    handleUnregister(userId){
        API.delete('/api/training-occurences/'+this.state.selected_occurence_id+'/users/'+userId)
        .then((res) => {
            this.loadTrainingOccurences();
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
        
    }

    componentWillUnmount(){
        this.source.cancel();
    }

    render(){
        if(this.state.redirect)
            return <Redirect to="/ctrainings" />
        
        const selected_date = this.state.selected_date;
        const occurences_date_selected = this.state.training.occurences.filter(occ => 
            occ.durations.filter(function(e){return e['start_date'].split("T")[0] == selected_date}).length > 0
        );

        let employees = []
        if (this.state.companies.length !== 0){
            this.state.companies.forEach((company) => {
                employees = employees.concat(company.employees)
            })
        }

        let availableEmployees = [];
        
        let selected_occurence;
        if (this.state.selected_occurence_id != undefined) {
            selected_occurence = this.state.training.occurences.find(occ => occ.id == this.state.selected_occurence_id);
            if(selected_occurence.students != undefined){
                availableEmployees = employees.filter(user => !selected_occurence.students.some(u => u.id == user.id));
            }else{
                availableEmployees = employees;
            }
        }

        let freelanceButton = <Button type="primary" onClick={this.handleRegister.bind(this,store.getState().user.profile.id)}>M'inscrire à la séance</Button>;
        if(store.getState().user.profile.role == "ROLE_FREELANCE" && selected_occurence != undefined){
            if(this.state.user.trainings.find(occ => occ.id == this.state.selected_occurence_id)!=undefined){
                freelanceButton=<Button type="primary" onClick={this.handleUnregister.bind(this,store.getState().user.profile.id)} danger>Me désinscrire de la séance</Button>;
            }
        }

        let companies = store.getState().companies.companies;

        //Vérification de la connexion de l'utilisateur
        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar actual_tab={"Trainings"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">                      
                    <Header>
                        <GoBackTitle route="/ctrainings" title="Revenir aux formations"/>
                    </Header>
                    {this.state.user.selected && this.state.training.id != undefined ?
                    <Content className="app-content">
                        <Row gutter={[48, 48]}>
                            {/* LeftPanel */}
                            <Col span={6}>
                                <Space size={[22, 8]} direction="vertical">
                                    <TrainingDetailsCard training={this.state.training} refresh_handler={this.loadTrainingDetail.bind(this)} handle_back={this.handleBack.bind(this)} isAdmin={false}/>  
                                </Space>                          
                            </Col>
                                
                            <Col span={10}>
                                
                                {selected_occurence != undefined ?
                                    <Space size={[50, 8]} direction="vertical">
                                        <SeancesExerptDetail places={this.state.places} placeChange_handler={this.updatePlaceTrainingOccurence} training={selected_occurence} />               
                                        {selected_occurence != undefined &&
                                            <Tabs defaultActiveKey="1">
                                                <TabPane tab={`Employé disponibles - ${availableEmployees.length}`}  key="1">
                                                    <Content>
                                                        <List
                                                            size="small"
                                                            dataSource={availableEmployees}
                                                            renderItem={item => (
                                                                <SmallEmployeeCard key={item.id} user={item} companies={companies} registered={false} action={this.handleRegister.bind(this,item.id, item.pivot.company_id)}/>
                                                            )}
                                                            pagination={{
                                                                pageSize: 4,
                                                                hideOnSinglePage:true,
                                                                simple:true,
                                                                position:'top',
                                                            }}
                                                            locale={{emptyText:<Empty description="Aucune utilisateur disponible" image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}
                                                            />
                                                    </Content>
                                                </TabPane>
                                                <TabPane tab={`Employé inscrits`} key="2">
                                                    <Content>
                                                        <List
                                                            size="small"
                                                            dataSource={selected_occurence.students}
                                                            renderItem={item => (
                                                                <SmallEmployeeCard key={item.id} user={item} companies={companies} pivot={item.pivot} registered={true} action={this.handleUnregister.bind(this,item.id)}/>
                                                                )}
                                                            pagination={{
                                                                pageSize: 4,
                                                                hideOnSinglePage:true,
                                                                simple:true,
                                                                position:'top',
                                                            }}
                                                            locale={{emptyText:<Empty description="Aucune utilisateur inscrit" image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}
                                                            />
                                                    </Content>
                                                </TabPane>
                                            </Tabs>                                    
                                        }

                                        {store.getState().user.profile.role == "ROLE_FREELANCE" && selected_occurence != undefined &&
                                            <>{freelanceButton}</>
                                        }

                                    </Space>

                                    :<Empty
                                                image={<FileExclamationOutlined/>}
                                                description={
                                                <span>
                                                    Pas de séance prévue ce jour
                                                </span>
                                                }
                                            >
                                            </Empty>
                                    }
                            </Col>

                            <Col span={8}>
                                <Space size={[22, 8]} direction="vertical">
                                    {/* Calendar */}
                                    <Card>
                                        <TrainingCalendar defaultValue={this.state.selected_date} trainings={this.state.training.occurences} handler={this.handleDateSelect.bind(this)}/>    
                                    </Card>          
                                    {selected_occurence != undefined &&
                                    <Card>
                                        <Meta title="Séances"/>
                                        {/* Seances  */}
                                        <Seances id={selected_occurence.id} delete_handler={this.deleteTrainingOccurence} training_id={this.state.user.selected} refresh_handler={this.loadTrainingOccurences} occurences={occurences_date_selected} selected_date={this.state.selected_date} handler={this.handleOccurenceSelect.bind(this)}/>               
                                    </Card>
                                    }
                                </Space>
                            </Col>
                        </Row>
                    </Content>
                    :<Loading />}
                </Layout>
            </Layout>
        )
    }
}


const mapStateToProps = state => {
    return {
        user: state.user.profile,
        trainings: state.trainings.trainings,
        trainers: state.trainers.trainers,
        users: state.users.users
    };
};

export default connect(mapStateToProps)(TrainingPage);