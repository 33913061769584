//Import des composants utiles
import React from 'react';
import store from '../../Store/Store';
import { ActionCreators } from '../../Store/Action'
import API from '../../api';
import Navbar from '../../components/Navbar';
import { CreateANewUser, CreateANewCompany } from '../../components/Modal'

//Import des composants de design
import { Typography, Layout, Card, Input, Row, Col, Menu, Empty, List } from 'antd';
import { GlobalOutlined, UserOutlined } from '@ant-design/icons';
import { GoToButton } from '../../components/Component'
const { Title } = Typography;
const { Header, Sider, Content } = Layout;
const { Search } = Input;
const { Meta } = Card;

//Carte des entreprises et utilisateurs
export const ClientBigCard = ({type, data}) => {
    let icon = type==="COMPANY"?<GlobalOutlined />:<UserOutlined />;

    return (
        <Card
            cover={icon}
            actions={[<GoToButton id="editbtn" route={type==="COMPANY"?"/aenterprise":"/auser"} element_id={data.id}/>]}
        >
            {type === "COMPANY" ?<Meta title={data.company_name} />:<Meta title={data.last_name} description={data.first_name}/>}
        </Card>
    );
}

//Page de listing des clients
class Clients extends React.Component{
    constructor(props){
        super(props)

        //Récupération des informations dans le store
        this.state = {
            ...store.getState(),
            searchText : '',
            current: "companies"
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
        this.refreshUsers = this.refreshUsers.bind(this);
        this.refreshCompanies = this.refreshCompanies.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount(){
        this._isMounted = true;

        this.refreshUsers();
        this.refreshCompanies();
    }

    //Chargement de la liste de tous les utilisateurs
    refreshUsers(){
        if(this.state.user.isLogged){
            API.get('api/users')
            .then(users_data => {
                const users = users_data.data;
                store.dispatch(ActionCreators.updateUsers(users));
                this.setState(store.getState());
            }).catch(err => {
                if(err.response != null && err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }) 
        }
    }

    //Chargement de la liste de toutes les entreprises
    refreshCompanies(){
        if(this._isMounted){
            API.get('api/companies')
            .then(companies_data => {
                const companies = companies_data.data;
                store.dispatch(ActionCreators.updateCompanies(companies));
                this.setState(store.getState());
            }).catch(err => {
                if(err.response != null && err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }) 
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    //Prise en charge du changement de menu
    handleClick(event) {
        this.setState({ current: event.key })
    };

    //Recherche en direct
    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    //Recherche Hit Enter
    handleOnSearch(value){
        this.setState({searchText: value});
    }

    render(){
        //Filtrage des utilisateurs
        const clientsToDisplay = this.state.users.users.filter(client => {
            const clientFullName = (client.first_name + " " + client.last_name).toLowerCase();
            return clientFullName.includes(this.state.searchText.toLowerCase());
        });

        //Filtrage des entreprises
        const companiesToDisplay = this.state.companies.companies.filter(company => {
            return company.company_name.toLowerCase().includes(this.state.searchText.toLowerCase());
        });
        
        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar actual_tab={"Company"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Title level={1}>Gérer les utilisateurs</Title>
                    </Header>
                    <Content className="app-content">     
                        {/* NavMenu */}
                        <Row justify="space-between">
                            <Menu onClick={(e) => this.handleClick(e)} mode="horizontal" style={{flex:'auto'}} selectedKeys={this.state.current}>
                                <Menu.Item key="companies">
                                    Entreprises
                                </Menu.Item>
                                <Menu.Item key="clients">
                                    Utilisateurs
                                </Menu.Item>
                            </Menu>    
                              
                            <Col style={{display:'flex', alignItems:'end', margin:'0 25px'}}>
                                {this.state.current==="companies"? <Search placeholder="Rechercher une entreprise" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton/> :
                                    <Search placeholder="Rechercher un utilisateur" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton/>}
                                
                                {this.state.current==="companies"?<CreateANewCompany refresh_handler={this.refreshCompanies}/>:<CreateANewUser refresh_handler={this.refreshUsers}/>}
                            </Col>
                        </Row>             
                        <Row style={{paddingTop:20}}>
                            {/* Clients */}
                            <List
                                grid={{
                                    gutter: 32,
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                    lg: 4,
                                    xl: 6,
                                    xxl: 6,
                                }}
                                pagination={{
                                    pageSize: 12,
                                    hideOnSinglePage:true,
                                }}
                                dataSource={this.state.current==="companies"?companiesToDisplay:clientsToDisplay}
                                renderItem={item => (
                                    <ClientBigCard key={item.id} data={item} type={this.state.current==="companies"?"COMPANY":"USER"}/>
                                    )
                                }
                                locale={{emptyText:<Empty description="Aucun client trouvé" image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}
                            />                                   
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default Clients;