//Import des composants utiles
import React from 'react';
import API from '../../api';
import { ActionCreators } from '../../Store/Action'
import store from '../../Store/Store';
import Navbar from '../../components/Navbar';
import { AddATraining } from '../../components/Modal'

//Import des composants de design
import { Typography, Layout, Row, Col, List, Empty, Input, Switch } from 'antd';
import { TrainingBigCard } from '../../components/Component'
import { LockFilled} from '@ant-design/icons';
const { Title } = Typography;
const { Header, Sider, Content } = Layout;
const { Search } = Input;

//Page de listing des formations
class Trainings extends React.Component{
    constructor(props){
        super(props)

        //Récupération des informations dans le store
        this.state = {
            ...store.getState(),
            trainings: [],
            searchText : '',
            disabledChecked: false,
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;

        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
        this.setDisabledChecked = this.setDisabledChecked.bind(this);
    }

    componentDidMount(){
        this._isMounted = true;

        this.refreshTrainings();
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    refreshTrainings(){
        API.get('api/trainings')
        .then(trainings_data => {
            if(this._isMounted){
                this.setState({trainings : trainings_data.data});
            }
        }).catch(err => {
            if(err.response !== null && err.response.status === 401){
                store.dispatch(ActionCreators.logout());
            }
        }) 
    }

    setDisabledChecked(value) {
        if (value) {
            API.get('api/trainings?withTrashed=true').then(training_data => {
                this.setState({trainings: training_data.data});
            }).catch(err => {
                if(err.response !== null && err.response.status === 401){
                    store.dispatch(ActionCreators.logout());
                }
            }) 
        } else {
            this.refreshTrainings();
        }
        this.setState({ disabledChecked: value });
    }

    //Recherche en direct
    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    //Recherche Hit Enter
    handleOnSearch(value){
        this.setState({searchText: value});
    }

    render(){
        //Filtrage des formations
        const trainingsToDisplay = this.state.trainings.filter(training => {
            return training.name.includes(this.state.searchText.toLowerCase());
        });

        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar actual_tab={"Trainings"}/>
                </Sider>
                <Layout  hasSider={false} className="main-container">
                    <Header>
                        <Title level={1}>Gérer les formations</Title>
                    </Header>
                    <Content className="app-content">                        
                        <Row className="toolbar" justify="space-between">
                            <Col span={8}>
                                <Row>
                                    <Col>
                                        <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton/>
                                    </Col>
                                    <Col style={{backgroundColor:'#FCAE03', borderColor:'#FCAE03', display:'flex', alignItems:'center', padding:'0 10px'}}>
                                        <LockFilled style={{color:'white', marginRight:10}}/> <Switch checked={this.state.disabledChecked} onChange={this.setDisabledChecked} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <AddATraining refresh_handler={this.refreshTrainings.bind(this)}/>
                            </Col>
                        </Row>

                        {/* Liste des formations */}
                        <Row>
                            {/* Formation */}
                            <List
                                grid={{
                                    gutter: 32,
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                    lg: 4,
                                    xl: 6,
                                    xxl: 6,
                                }}
                                pagination={{
                                    pageSize: 12,
                                    hideOnSinglePage:true,
                                }}
                                dataSource={trainingsToDisplay}
                                renderItem={item => (
                                    <TrainingBigCard key={item.id} training={item} route="/atraining"/>
                                    )
                                }
                                locale={{emptyText:<Empty description="Aucune formation trouvée"  image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}
                            /> 
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default Trainings;