//Import des composants utiles
import React from 'react';
import API from '../../api';
import { ActionCreators } from '../../Store/Action'
import store from '../../Store/Store';
import { connect } from 'react-redux';
import Navbar from '../../components/Navbar';
import { AddATraining } from '../../components/Modal'

//Import des composants de design
import { Typography, Layout, Row, Col, List, Empty, Input, Switch } from 'antd';
import { TrainingBigCard } from '../../components/Component'
const { Title } = Typography;
const { Header, Sider, Content } = Layout;
const { Search } = Input;

//Page de listing des formations
class Trainings extends React.Component{
    constructor(props){
        super(props)

        //Récupération des informations dans le store
        this.state = {
            ...store.getState(),
            searchText : '',
            disabledChecked: false,
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;

        this.handleOnSearch = this.handleOnSearch.bind(this);
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
    }

    async componentDidMount(){
        this._isMounted = true;

        if(this.state.user.isLogged){
            API.get('api/trainings')
            .then(trainings_data => {
                if(this._isMounted){
                    store.dispatch(ActionCreators.updateTrainings(trainings_data.data));
                    this.setState(store.getState());
                }
            }).catch(err => {
                if(err.response != null && err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }) 
        }
    }

    refreshTrainings(){
        if(this.state.user.isLogged){
            API.get('api/trainings')
            .then(trainings_data => {
                if(this._isMounted){
                    store.dispatch(ActionCreators.updateTrainings(trainings_data.data));
                    this.setState(store.getState());
                }
            }).catch(err => {
                if(err.response != null && err.response.status == 401){
                    store.dispatch(ActionCreators.logout());
                }
            }) 
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleOnSearch(value){
        this.setState({searchText: value});
    }

    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    render(){
        const trainingsToDisplay = this.state.trainings.trainings.filter(training => {
            return training.name.includes(this.state.searchText.toLowerCase());
        });
        //Vérification de la connexion de l'utilisateur
        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar actual_tab={"Trainings"}/>
                </Sider>
                <Layout  hasSider={false} className="main-container">
                    <Header>
                        <Title level={1}>Liste de formations</Title>
                    </Header>
                    <Content className="app-content">                        
                        <Row className="toolbar" justify="space-between">
                            <Col span={8}>
                                <Row>
                                    <Col>
                                        <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {/* Liste des formations */}
                        <Row>
                            {/* Formation */}
                            <List
                                grid={{
                                    gutter: 32,
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                    lg: 4,
                                    xl: 6,
                                    xxl: 6,
                                }}
                                pagination={{
                                    pageSize: 12,
                                    hideOnSinglePage:true,
                                }}
                                dataSource={trainingsToDisplay}
                                renderItem={item => (
                                    <TrainingBigCard key={item.id} training={item} route="/utraining"/>
                                    )
                                }
                                locale={{emptyText:<Empty description="Aucune formation trouvée"  image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}
                            /> 
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.profile,
        trainings: state.trainings.trainings,
        trainers: state.trainers.trainers,
        users: state.users.users
    };
};

export default connect(mapStateToProps)(Trainings);