//Import des composants utiles
import React from 'react';
import API from '../../api';

//Import des composants de design
import { BigCard } from '../../components/Component';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Col, Empty,Input, Layout, Typography, Row, List } from 'antd';
import Navbar from '../../components/Navbar';
import { AddANewPlace } from '../../components/Modal';
const { Title } = Typography;
const { Search } = Input;
const { Header, Sider, Content } = Layout;

class Places extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      places: [],
      searchText: '',
    }

    this.loadData = this.loadData.bind(this);
    this.handleOnSearch = this.handleOnSearch.bind(this);
    this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);

    this.loadData();
  }

  loadData() {
    API.get('/api/places').then(res => {
      this.setState({ places: res.data });
    });
  }

  //Recherche en direct
  handleOnChangeSearch(event){
      this.setState({searchText: event.target.value});
  }

  //Recherche Hit Enter
  handleOnSearch(value){
      this.setState({searchText: value});
  }

  render() {
    //Filtrage des lieux de formation
    const placesToDisplay = this.state.places.filter(place => {
      return place.name.toLowerCase().includes(this.state.searchText.toLocaleLowerCase())
    });

    return (
      <Layout>
        <Sider width={80} className="main_nav">
          <Navbar actual_tab={"Places"} />
        </Sider>
        <Layout hasSider={false} className="main-container">
          <Header>
            <Title level={1}>Gérer les lieux de formation</Title>
          </Header>
          <Content className="app-content">
            <Row className="toolbar" justify="space-between">
              <Col span={4}>
                <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton />
              </Col>
              <Col>
                <AddANewPlace refresh_handler={this.loadData} />
              </Col>
            </Row>

            {/* Liste des Lieux */}
            <Row>
              {/* Lieux */}
              <List
                grid={{
                  gutter: 32,
                  xs: 1,
                  sm: 2,
                  md: 4,
                  lg: 4,
                  xl: 6,
                  xxl: 6,
                }}
                pagination={{
                  pageSize: 12,
                  hideOnSinglePage: true,
                }}
                dataSource={placesToDisplay}
                renderItem={place => (
                  <BigCard key={place.id} icon={<EnvironmentOutlined />} name={place.name} route="/aplace" element_id={place.id} />
                )}
                locale={{ emptyText: <Empty description="Aucun lieu trouvée" /> }}
              />
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  }
}

export default Places;