import { createStore, combineReducers } from 'redux'
import User from './Reducers/User';
import Trainings from './Reducers/Trainings';
import Trainers from './Reducers/Trainers';
import Users from './Reducers/Users';
import Companies from './Reducers/Companies';
import Certificates from './Reducers/Certificates';
import { loadState, saveState } from './LocalStorage';

//Rassemblement des différents reducers
const rootReducer = combineReducers({
    user: User,
    trainings: Trainings,
    trainers: Trainers,
    users: Users,
    companies: Companies,
    certificates: Certificates,
})

const persistedState = loadState();

//Création du store
const store = createStore(
    rootReducer,
    persistedState
)

//Sauvegarde du store lors des modifications
store.subscribe(() => {
    saveState(store.getState());
}); 

export default store;