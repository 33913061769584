//Import des composants utiles
import React from 'react';
import API from '../api';
import { Link } from "react-router-dom";
import { GetExpirationDate } from '../utils';
import store from '../Store/Store';
import { ActionCreators } from '../Store/Action'

//Import des composants de design
import { Card, Button, Typography, Layout, Spin, Popconfirm, message, Row, List } from 'antd';
import { MailOutlined, PercentageOutlined, NumberOutlined, PhoneOutlined, EnvironmentOutlined, FileTextOutlined, FundProjectionScreenOutlined, SafetyCertificateOutlined, ArrowRightOutlined, LoadingOutlined, AuditOutlined, ArrowLeftOutlined, UserDeleteOutlined, UserOutlined  } from '@ant-design/icons';
import Moment from 'moment';
const { Text } = Typography;
const { Sider, Content } = Layout;
const { Meta } = Card;
import { EditSeanceDetail, EditUserCertificate } from './Modal';

//Bouton principale pour la sélection
export function GoToButton(props){
    const handleClick = () => {
        store.dispatch(ActionCreators.updateSelected(props.element_id));
    }

    return(
        <Link to={{pathname:props.route}} style={{padding:0}}>
            <Button id={props.id} onClick={handleClick} type="text" size='large' icon={<ArrowRightOutlined />}/>
        </Link>
    );
}

//Titre de retour
export function GoBackTitle(props){
    return(     
        <Link id="main-title" to={props.route} className="backbutton"><Button type="text" size="large" icon={<ArrowLeftOutlined />}>{props.title}</Button></Link>
    );
}

//Paragraphe composé d'informations de base
export function SimpleInformation(props){
    return(
        <>
            <Layout className="light-background" style={{height:45}}>
                <Sider className="light-background" width={25}>
                    <EnvironmentOutlined />
                </Sider>
                <Content>
                    <Text style={{fontSize:14, fontWeight:400}}>{props.address}</Text>
                </Content>
            </Layout>
            <Layout className="light-background" style={{height:45}}>
                <Sider className="light-background" width={25}>
                    <PhoneOutlined />
                </Sider>
                <Content>
                    <Text style={{fontSize:14, fontWeight:400}}>{props.gsm}</Text>
                </Content>
            </Layout>
            <Layout className="light-background" style={{height:45}}>
                <Sider className="light-background" width={25}>
                    <MailOutlined />
                </Sider>
                <Content>
                    <Text style={{fontSize:14, fontWeight:400}}>{props.mail}</Text>
                </Content>
            </Layout>
        </>
    )
}

//Affichage d'une description courte avec une icone dépendant du type
export function ShortDescription(props){
    let icon;
    let date;
    if (!Moment.isMoment(props.date)) {
        date = Moment(props.date).utc();
    }
    // const realdate = ConvertDate(props.date)
    // const date = "Réalisée le " + realdate.day + " " + realdate.monthString + " " + realdate.year
    date = `${date.format('L')}`;
    switch (props.type) {
        case "doc":
            icon = <FileTextOutlined />
            break;
        case "training":
            icon = <FundProjectionScreenOutlined />
            break;
        case "certificate":
            icon = <SafetyCertificateOutlined />   
            break;      
        default:
            break;
    }
    return(
        <List.Item>
            <List.Item.Meta
                avatar={icon}
                title={props.title}
                description={date}
            />
        </List.Item>
    )
}

//Description d'une prochaine occurence de formation
export const NextTrainingOcc = ({occurence, date_select}) => {
    let date;
    if (!Moment.isMoment(occurence.date)) {
        date = Moment.utc(occurence.date);
    }
    date = `${date.format('L')}`;

    return(
        <List.Item onClick={date_select}>
            <List.Item.Meta
                avatar={<FundProjectionScreenOutlined />}
                title={occurence.place}
                description={date}
            />
        </List.Item>
    )
}

//Carte de résumé d'une occurence d'un employé
export const SmallTrainingDetails = ({occurence, refresh_handler}) => {
    let date;
    if (!Moment.isMoment(occurence.date)) {
        date = Moment(occurence.date).utc();
    }
    date = `${date.format('L')}`;
    
    return(
        <List.Item>
            <List.Item.Meta
                avatar={<FundProjectionScreenOutlined />}
                title={occurence.title + " - " + date}
                description={<>
                {occurence.discount_number &&
                    <div style={{display:'flex', alignItems:'center'}}>
                        <PercentageOutlined /> &nbsp;&nbsp;
                        {occurence.discount_number}
                    </div>
                }
                {occurence.usage_number && 
                    <div style={{display:'flex', alignItems:'center'}}>
                        <NumberOutlined /> &nbsp;&nbsp;
                        {occurence.usage_number}
                    </div>                   
                }
                </>}
            />
            <EditSeanceDetail occurence={occurence} refresh_handler={refresh_handler}/>
        </List.Item>
    )
}

//Carte d'un certificat !
export const UserCertificatesCard = ({certificate, user, refresh_handler}) => {
    let date = Moment(GetExpirationDate(new Date(certificate.pivot.granted), certificate.validity)).utc().format('L');
    let isClose = Moment().utc().isBetween(Moment(GetExpirationDate(new Date(certificate.pivot.granted), certificate.validity)).utc().subtract(2, 'month'), Moment(GetExpirationDate(new Date(certificate.pivot.granted), certificate.validity)).utc())

    return (
        <Layout className="light-background">
            <Sider id="card-btn-sider" theme='light' width={20}>
                <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.75 3.45312V0H8.4375C8.74922 0 9 0.27168 9 0.609375V12.3906C9 12.7283 8.74922 13 8.4375 13H0.5625C0.250781 13 0 12.7283 0 12.3906V4.0625H3.1875C3.49687 4.0625 3.75 3.78828 3.75 3.45312ZM2.25 9.44531C2.25 9.61289 2.37656 9.75 2.53125 9.75H6.46875C6.62344 9.75 6.75 9.61289 6.75 9.44531V9.24219C6.75 9.07461 6.62344 8.9375 6.46875 8.9375H2.53125C2.37656 8.9375 2.25 9.07461 2.25 9.24219V9.44531ZM2.25 7.82031C2.25 7.98789 2.37656 8.125 2.53125 8.125H6.46875C6.62344 8.125 6.75 7.98789 6.75 7.82031V7.61719C6.75 7.44961 6.62344 7.3125 6.46875 7.3125H2.53125C2.37656 7.3125 2.25 7.44961 2.25 7.61719V7.82031ZM2.25 5.99219V6.19531C2.25 6.36289 2.37656 6.5 2.53125 6.5H6.46875C6.62344 6.5 6.75 6.36289 6.75 6.19531V5.99219C6.75 5.82461 6.62344 5.6875 6.46875 5.6875H2.53125C2.37656 5.6875 2.25 5.82461 2.25 5.99219ZM0 3.09512V3.25H3V0H2.85703C2.70703 0 2.56406 0.0634766 2.45859 0.177734L0.164062 2.66602C0.0585938 2.78027 0 2.93516 0 3.09512Z" fill="#FCAE03"/>
                </svg>
            </Sider>
            <Content className="training-content" style={{width:0, display:'flex', flexDirection:'column'}}>
                <Text ellipsis={true} style={{fontSize:13, fontWeight:700}}>{certificate.name}</Text>
                <Text style={{fontSize:11, fontWeight:400}}>Expire le {date}</Text>
            </Content>
            <Sider id="card-btn-sider" theme='light' width={70}>
                <Row style={{justifyContent:'space-around', alignItems:'center'}}>
                    {isClose && <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.8312 12.0316C15.3119 12.9064 14.7085 14 13.7485 14H1.25142C0.289513 14 -0.311061 12.9047 0.16868 12.0316L6.41728 0.65584C6.89819 -0.219406 8.1027 -0.21782 8.58275 0.65584L14.8312 12.0316ZM7.50002 9.67969C6.83843 9.67969 6.3021 10.2428 6.3021 10.9375C6.3021 11.6322 6.83843 12.1953 7.50002 12.1953C8.1616 12.1953 8.69793 11.6322 8.69793 10.9375C8.69793 10.2428 8.1616 9.67969 7.50002 9.67969ZM6.3627 5.15851L6.55587 8.87726C6.56491 9.05127 6.70194 9.1875 6.8679 9.1875H8.13212C8.29809 9.1875 8.43512 9.05127 8.44416 8.87726L8.63733 5.15851C8.6471 4.97055 8.50457 4.8125 8.3253 4.8125H6.6747C6.49543 4.8125 6.35293 4.97055 6.3627 5.15851Z" fill="#FCAE03"/>
                    </svg>}

                    <EditUserCertificate certificate={certificate} user={user} refresh_handler={refresh_handler}/>
                </Row>
            </Sider>
        </Layout>
    );
}

//Carte d'une facture !
export const CompanyInvoicesCard = ({invoice}) => {
    let date = Moment(invoice.created_at).utc().format('L');

    //Prise en charge du téléchargement du fichier
    const downloadFile = () => {
        try {
            API.get(invoice.url, {
                responseType: 'blob'
            }).then(res => {
                const fileDownload = require('js-file-download');
                fileDownload(res.data, invoice.name)
            });
        } catch (err){
            throw new Error('Error When Downloading File :' + err );
        }
    }

    return (
        <List.Item 
            actions={[
            <Button onClick={downloadFile} type="text" icon={
                <svg width="21" height="27" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.16667 4.78125V0H0.875C0.390104 0 0 0.376172 0 0.84375V17.1562C0 17.6238 0.390104 18 0.875 18H13.125C13.6099 18 14 17.6238 14 17.1562V5.625H9.04167C8.56042 5.625 8.16667 5.24531 8.16667 4.78125ZM10.9539 12.2119L7.43859 15.5763C7.19615 15.8087 6.80458 15.8087 6.56214 15.5763L3.04682 12.2119C2.67677 11.8579 2.93635 11.25 3.45698 11.25H5.83333V8.4375C5.83333 8.12672 6.09437 7.875 6.41667 7.875H7.58333C7.90562 7.875 8.16667 8.12672 8.16667 8.4375V11.25H10.543C11.0636 11.25 11.3232 11.8579 10.9539 12.2119ZM13.7448 3.69141L10.1755 0.246094C10.0115 0.0878906 9.78906 0 9.55573 0H9.33333V4.5H14V4.28555C14 4.06406 13.9089 3.84961 13.7448 3.69141Z" fill="#D7D7D7"/>
                </svg>                        
            }/>]}
        >
            <List.Item.Meta
                title={invoice.name}
                description={`Généré le ${date}`}
            />
        </List.Item>
    );
}

//Carte d'une facture !
export const UserInvoicesCard = ({invoice}) => {
    let date = Moment(invoice.created_at).utc().format('L');

    //Prise en charge du téléchargement du fichier
    const downloadFile = () => {
        try {
            API.get(invoice.url, {
                responseType: 'blob'
            }).then(res => {
                const fileDownload = require('js-file-download');
                fileDownload(res.data, invoice.name)
            });
        } catch (err){
            throw new Error('Error When Downloading File :' + err );
        }
    }

    return (
        <Layout className="light-background">
            <Sider id="card-btn-sider" theme='light' width={20}>
                <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.75 3.45312V0H8.4375C8.74922 0 9 0.27168 9 0.609375V12.3906C9 12.7283 8.74922 13 8.4375 13H0.5625C0.250781 13 0 12.7283 0 12.3906V4.0625H3.1875C3.49687 4.0625 3.75 3.78828 3.75 3.45312ZM2.25 9.44531C2.25 9.61289 2.37656 9.75 2.53125 9.75H6.46875C6.62344 9.75 6.75 9.61289 6.75 9.44531V9.24219C6.75 9.07461 6.62344 8.9375 6.46875 8.9375H2.53125C2.37656 8.9375 2.25 9.07461 2.25 9.24219V9.44531ZM2.25 7.82031C2.25 7.98789 2.37656 8.125 2.53125 8.125H6.46875C6.62344 8.125 6.75 7.98789 6.75 7.82031V7.61719C6.75 7.44961 6.62344 7.3125 6.46875 7.3125H2.53125C2.37656 7.3125 2.25 7.44961 2.25 7.61719V7.82031ZM2.25 5.99219V6.19531C2.25 6.36289 2.37656 6.5 2.53125 6.5H6.46875C6.62344 6.5 6.75 6.36289 6.75 6.19531V5.99219C6.75 5.82461 6.62344 5.6875 6.46875 5.6875H2.53125C2.37656 5.6875 2.25 5.82461 2.25 5.99219ZM0 3.09512V3.25H3V0H2.85703C2.70703 0 2.56406 0.0634766 2.45859 0.177734L0.164062 2.66602C0.0585938 2.78027 0 2.93516 0 3.09512Z" fill="#FCAE03"/>
                </svg>
            </Sider>
            <Content className="training-content" style={{width:0, display:'flex', flexDirection:'column'}}>
                <Text ellipsis={true} style={{fontSize:13, fontWeight:700}}>{invoice.name}</Text>
                <Text style={{fontSize:11, fontWeight:400}}>Généré le {date}</Text>
            </Content>
            <Sider id="card-btn-sider" theme='light' width={70}>
                <Row style={{justifyContent:'space-around', alignItems:'center'}}>
                    <Button onClick={downloadFile} type="text" icon={
                        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.16667 4.78125V0H0.875C0.390104 0 0 0.376172 0 0.84375V17.1562C0 17.6238 0.390104 18 0.875 18H13.125C13.6099 18 14 17.6238 14 17.1562V5.625H9.04167C8.56042 5.625 8.16667 5.24531 8.16667 4.78125ZM10.9539 12.2119L7.43859 15.5763C7.19615 15.8087 6.80458 15.8087 6.56214 15.5763L3.04682 12.2119C2.67677 11.8579 2.93635 11.25 3.45698 11.25H5.83333V8.4375C5.83333 8.12672 6.09437 7.875 6.41667 7.875H7.58333C7.90562 7.875 8.16667 8.12672 8.16667 8.4375V11.25H10.543C11.0636 11.25 11.3232 11.8579 10.9539 12.2119ZM13.7448 3.69141L10.1755 0.246094C10.0115 0.0878906 9.78906 0 9.55573 0H9.33333V4.5H14V4.28555C14 4.06406 13.9089 3.84961 13.7448 3.69141Z" fill="#D7D7D7"/>
                        </svg>                        
                    }/>
                </Row>
            </Sider>
        </Layout>
    );
}

//Carte des formations effectuées (DashBoard)
export function SmallTrainingCard(props){
    //Conversion de la date 

    const handleClick = () => {store.dispatch(ActionCreators.updateSelected(props.data.training_id));}
    
    return (
        <List.Item bordered="false"
            actions={[<Link to={props.isAdmin?{pathname:'/atraining', state: {refDate: props.data}}:props.isUser?{pathname:'/utraining', state: {refDate: props.data}}:{pathname:'/ttraining', state: {refDate: props.data}}}><Button onClick={handleClick} type="text" icon={<ArrowRightOutlined />}/></Link>]}
        >
            <List.Item.Meta
                avatar={
                    <AuditOutlined />
                }
                title={props.data.name + " - " + props.data.place}
                description={props.data.date}
              />
        </List.Item>
    );
}

//Carte des documents générer (Dashboard)
export function SmallDocumentCard(props){
    return (
        <List.Item>
            <List.Item.Meta
                avatar={
                    <AuditOutlined />
                }
                title={props.data.name}
                description="Génération de la facture"
              />
        </List.Item>
    );
}

//Carte d'un employé (Entreprise)
export function EmployeeCard(props){
    const handleDelete = () => { 
        API.delete('/api/companies/'+props.employee.pivot.company_id+'/users/'+props.employee.id)
        if (props.refresh_handler) props.refresh_handler();
    }

    const deleteConfirm = () => {
        handleDelete();
        message.success("Suppression de l'employé !");
    }

    return (
        <List.Item 
            onClick={props.selection_handler} 
            className={props.selected?"list_selected itemClick":"itemClick"}
            actions={[
            <Popconfirm
                title="Voulez-vous vraiment supprimer cet employé ?"
                onConfirm={deleteConfirm}
                okText="Oui"
                cancelText="Non"
            ><Button type="text" id={props.id} icon={<UserDeleteOutlined />}/>
            </Popconfirm>]}
        >
            <List.Item.Meta
                avatar={<UserOutlined />}
                title={props.employee.last_name}
                description={props.employee.first_name}
            />
        </List.Item>   
    );
}

//Carte de listage d'une formation (Trainings)
export function TrainingBigCard(props){
    return (
        <Card
            cover={<FundProjectionScreenOutlined />}
            actions={[<GoToButton id="editbtn" route={props.route} element_id={props.training.id}/>]}
        >
            <Meta title={props.training.name} />
        </Card>
    );
}

export function Loading(){
    const antIcon = <LoadingOutlined style={{ fontSize: 80, color:'orange' }} spin />;

    return (
        <>
            <Spin indicator={antIcon} style={{margin:'auto', paddingBottom:100}}/>
        </>
    );
}

export function BigCard (props) {
    return (
        <Card
            cover={props.icon}
            actions={[<GoToButton id="editbtn" route={props.route} element_id={props.element_id}/>]}
        >
            <Meta title={props.name} />
        </Card>
    );
}

