//Import des composants utiles
import React from 'react';
import API from '../../api';
import moment from 'moment';
import store from '../../Store/Store';

//Import des composants de design
import { GoBackTitle } from '../../components/Component';
import Navbar from '../../components/Navbar';
import { Card, Col, Layout, Row, Spin, List, Empty,Typography } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { CalendarFullScreenFalse } from '../../components/RightPanel';
import { PlaceBigCard } from './Parameters';
import { FundProjectionScreenOutlined } from '@ant-design/icons';
const { Title } = Typography;


class Place extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      place: null,
      dateSelected: moment(),
    }

    this.loadData = this.loadData.bind(this);
    this.onSelect = this.onSelect.bind(this);

    this.loadData(store.getState().user.selected);
  }

  loadData(id = this.state.place.id) {
    API.get('/api/places/' + id).then(res => {
      this.setState({ place: res.data });
    });

    API.get('/api/places/' + id + '/training-occurences').then(res => {
      const trainingOccurences = res.data;
      const promises = [];

      promises.push(Promise.all([...new Set(trainingOccurences.map((tr_occ) => tr_occ.training_id))].map(training_id => API.get('/api/trainings/' + training_id))).then(res => {
        const trainings = res.map(o => o.data);
        trainingOccurences.forEach(occ => {
          occ.training = trainings.find(t => t.id === occ.training_id);
        });
      }));

      promises.push(...trainingOccurences.map(tr_occ => {
        return API.get('/api/training-occurences/' + tr_occ.id + '/durations').then(res =>
          tr_occ.durations = res.data
        );
      }));

      Promise.all(promises).then(() => {
        this.setState({
          place: {
            ...this.state.place,
            training_occurences: trainingOccurences
          }
        });
      });
    });
  }

  onSelect(dateSelected) {
    this.setState({ dateSelected });
  }

  render() {
    const dates = [];

    if (this.state.place && this.state.place.training_occurences) {
      this.state.place.training_occurences.forEach((occ) => {
        occ.durations.forEach((duration) => {
          dates.push(moment(duration.start_date));
          dates.push(moment(duration.end_date));
        });
      });
    }

    const listTrainingOccurencesForADate = () => {
      if (this.state.place == null || this.state.place.training_occurences == null) return [];

      const arrayToReturn = [];

      this.state.place.training_occurences.forEach(tr_occ => {
        tr_occ.durations.forEach(duration => {
          if (moment(duration.start_date).isSame(this.state.dateSelected, 'day') || moment(duration.end_date).isSame(this.state.dateSelected, 'day')) {
            arrayToReturn.push({
              ...tr_occ,
              duration: {
                start_date: moment.utc(duration.start_date),
                end_date: moment.utc(duration.end_date),
              },
            });
          }
        });
      });

      return arrayToReturn;
  }

    return (
      <Layout>
        <Sider width={80} className="main_nav">
          <Navbar actual_tab={"Place"} />
        </Sider>
        <Layout hasSider={false} className="main-container">
          <Header>
            <GoBackTitle route="/aplaces" title="Revenir à la liste des lieux" />
          </Header>
          {this.state.place ?
            <Content className="app-content">
              <Row gutter={[48, 48]}>
                <Col span={6}>
                  <PlaceBigCard refresh_handler={this.loadData} place={this.state.place} />
                </Col>
                <Col span={10}>
                  <Title level={5}>Formations</Title>
                    <List size="small" dataSource={listTrainingOccurencesForADate()} renderItem={training_occurence => (
                        <List.Item>
                          <List.Item.Meta
                            avatar={<FundProjectionScreenOutlined />}
                            title={training_occurence.training.name}
                            description={training_occurence.duration.start_date.format('LT') + ' - ' + training_occurence.duration.end_date.format('LT')}
                          />
                        </List.Item>
                      )}
                      pagination={{
                        pageSize: 4,
                        hideOnSinglePage: true,
                        simple: true,
                        position: 'top',
                      }}
                      locale={{ emptyText: <Empty description="Aucune formation" image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}>
                    </List>
                </Col>
                <Col span={8}>
                  <Card>
                    <CalendarFullScreenFalse dates={dates} onSelect={this.onSelect} />
                  </Card>
                </Col>
              </Row>
            </Content>
            :
            <Spin />
          }
        </Layout>
      </Layout>
    );
  }
}

export default Place;