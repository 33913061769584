//Import des composants utiles
import React from 'react';
import API from '../api';
import { ActionCreators } from '../Store/Action';
import store from '../Store/Store';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

//Import des composants de design
import { LeftOutlined } from '@ant-design/icons';
import { Form, Input, Card, Button, Typography, Checkbox, Alert, Row, Col } from 'antd';
const { Text } = Typography;

import { PasswordReset } from '../components/Modal';


//Page de connexion de l'utilisateur
class Login extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            email: "",
            password: "",
            error_message: '',
            remember: false,
            continue : false,
            role:"",
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    //Prise en charge de la modification des champs
    handleChange(event){
        if(this._isMounted)
            this.setState({[event.target.name]: event.target.value})

    }

    handleCheckedChange(event){
        if(this._isMounted)
            this.setState({[event.target.name]: event.target.checked})
    }

    //Prise en charge de la connexion de l'utilisateur
    async login(){
        if(this._isMounted)
            this.setState({error_message: ""})
        
        let obj = {};
        obj.national_number = this.state.email;
        obj.password = this.state.password;
        obj.remember = this.state.remember;

        API.post('/login',obj)
        .then((res) => {
            if(res.status === 200 && res.data.id != null){
                API.get(`api/users/${res.data.id}/companies`)
                .then(companies_data => {
                    const companies = companies_data.data.filter(company => company.pivot.role==="ROLE_ADMIN");
                    store.dispatch(ActionCreators.updateCompanies(companies));
                }).catch(err => {
                    if(err.response != null && err.response.status == 401){
                        store.dispatch(ActionCreators.logout());
                    }
                }) 
                store.dispatch(ActionCreators.login(res.data));
                this.setState({continue : true, email: null, pass: null, role:res.data.role});
            }else {
                this.setState({error_message: "Réessayer"});
                store.dispatch(ActionCreators.logout());
                API.post('/logout'); 
            }           
        }).catch(err => {
            if(err.response === undefined){
                this.setState({error_message: "Email ou mot de passe incorrect"})
            }else{
                this.setState({error_message: "Erreur Inconnue !"})
            }
        })      
    }

    componentDidMount(){
        this._isMounted = true;

        if (store.getState().user.fromRegister) {
            this.setState({
                email : store.getState().user.profile.email,
            });
        }

        if (store.getState().user.isLogged) {
            this.setState({continue: true});
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    render(){

        //Redirection sur l'utilisateur est connecté
        if(this.state.continue){
            switch (store.getState().user.profile.role){
                case 'ROLE_ADMIN':
                    return <Redirect to='/adashboard' />;
                case 'ROLE_TRAINER':
                    return <Redirect to='/tdashboard' />;
                case 'ROLE_USER':
                    return <Redirect to='/dashboard' />;
            }
        }

        return (
            <Row id="login" justify="center" align="middle">
                <Col span={8}>
                    <Card
                        actions={[<Link to="/register" style={{paddingTop:0}}><Button type="text" size='large'>Créer un compte</Button></Link>,<Button type="primary" size='large' form="loginForm" key="submit" htmlType="submit">Se connecter</Button>]}
                        cover={<img src="img/logo.png"/>}
                    >
                        <Row>
                            <Button type="primary" icon={<LeftOutlined />} href="/"/>
                        </Row>
                        {store.getState().user.fromRegister ? <><Alert message="Inscription réussie, veuillez vous identifier" type="success" /></> : <></>}
                        <Form id="loginForm" layout="vertical" onFinish={this.login.bind(this)}> 
                            <Form.Item label="Numéro de registre national" name="email" rules={[{ required: true, message: 'Veuillez entrer votre numéro de registre national' }]}>     
                                <Input autoComplete={"username"} name={"email"} onChange={this.handleChange.bind(this)} value={this.state.email} placeholder={"Entrez votre numéro de registre national"}/>
                            </Form.Item>
                            <Form.Item label="Mot de passe" name="password" rules={[{ required: true, message: 'Veuillez entrer votre mot de passe' }]}>
                                <Input.Password autoComplete={"current-password"} name={"password"} onChange={this.handleChange.bind(this)} placeholder={"Entrez votre mot de passe"}/>
                            </Form.Item>
                            <Row justify="space-between" >
                                <Form.Item>                                    
                                        <Checkbox name={"remember"} onChange={this.handleCheckedChange.bind(this)}>Se souvenir de moi</Checkbox>
                                </Form.Item>
                                <PasswordReset />
                            </Row>
                        </Form>
                        {this.state.error_message !== "" &&
                        <Row justify="center">
                            <Text type="danger">{this.state.error_message}</Text>
                        </Row>
                        }
                        
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.profile,
        trainings: state.trainings.trainings,
        trainers: state.trainers.trainers,
        users: state.users.users
    };
};

export default connect(mapStateToProps)(Login);