import { Types } from '../Action';

//Etat initial de l'utilisateur
const initialState = {
    companies: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.UPDATE_COMPANIES:
      return {
        ...state,
        companies: action.payload.companies,
      }
    default:
      return state;
  }
}

export default reducer;