//Import des composants utiles
import React from 'react';
import store from '../Store/Store';
import ActionCreators from '../Store/Action'
import { Link, useHistory, Redirect } from "react-router-dom";
import API from '../api';
import CookieConsent from "react-cookie-consent";

//Import des composants de design
import { Avatar, Menu, Space } from 'antd';
import { FileDoneOutlined, HomeOutlined, TeamOutlined, FundProjectionScreenOutlined, SolutionOutlined,SettingOutlined,LogoutOutlined , EnvironmentOutlined } from '@ant-design/icons';

export default function Navbar(props){
    let history = useHistory();

    const Disconnect = () => {
        API.post('/logout')
        .then((res) => {
            if(res.status == 200 || res.status == 204){
                store.dispatch(ActionCreators.logout());
                history.push('/') 
            }    
        }).catch((err) => {
            store.dispatch(ActionCreators.logout());
            history.push('/')
        })
    }

    return store.getState().user.isLogged?(
        <Space direction="vertical" size={0} className="navbar">
            <Menu mode="inline">
                    {store.getState().user.profile.role == "ROLE_ADMIN"&&<>

                        {/* Dashboard */}
                        <Menu.Item key="1" className={props.actual_tab==="Dashboard"?"selected":""}>
                            <Link to={{pathname:"/adashboard"}}>
                                    <HomeOutlined />
                            </Link>
                        </Menu.Item>

                        {/* Clients */}
                        <Menu.Item key="2" className={props.actual_tab==="Company"?"selected":""}>
                            <Link to={{pathname:"/aclients"}}>
                                    <TeamOutlined />
                            </Link>
                        </Menu.Item>

                        {/* Trainings */}
                        <Menu.Item key="3" className={props.actual_tab==="Trainings"?"selected":""}>
                            <Link to={{pathname:"/atrainings"}}>
                                    <FundProjectionScreenOutlined />
                            </Link>
                        </Menu.Item>

                        {/* Trainers */}
                        <Menu.Item key="4" className={props.actual_tab==="Trainers"?"selected":""}>
                            <Link to={{pathname:"/atrainers"}}>
                                    <SolutionOutlined />
                            </Link>
                        </Menu.Item>

                        {/* Certificate */}
                        <Menu.Item key="17" className={props.actual_tab==="Certificate"?"selected":""}>
                            <Link to={{pathname:"/acertificates"}}>
                                    <FileDoneOutlined />
                            </Link>
                        </Menu.Item>

                        {/* Lieux des formations */}
                        <Menu.Item key="18" className={props.actual_tab==="Places"?"selected":""}>
                            <Link to={{pathname:"/aplaces"}}>
                                <EnvironmentOutlined />
                            </Link>
                        </Menu.Item>

                        {/* Parameters */}
                        <Menu.Item key="5" className={props.actual_tab==="Parameters"?"selected":""}>
                            <Link to={{pathname:"/parameters"}}>
                                <SettingOutlined />
                            </Link>
                        </Menu.Item>
                    </>}

                    {store.getState().user.profile.role == "ROLE_TRAINER" &&<>

                        {/* Trainings */}
                        <Menu.Item key="6" className={props.actual_tab==="Dashboard"?"selected":""}>
                            <Link to={{pathname:"/tdashboard"}}>
                                <FundProjectionScreenOutlined />
                            </Link>
                        </Menu.Item>

                    </>}

                    {store.getState().user.profile.role == "ROLE_USER" &&<>

                        <Menu.Item key="8" className={props.actual_tab==="Dashboard"?"selected":""}>
                            <Link to={{pathname:"/dashboard"}}>
                                <HomeOutlined />
                            </Link>
                        </Menu.Item>

                        {store.getState().companies.companies.length > 0 &&
                            <Menu.Item key="2" className={props.actual_tab==="Company"?"selected":""}>
                                <Link to={{pathname:"/companies"}}>
                                    <TeamOutlined />
                                </Link>
                            </Menu.Item>
                        }

                        {store.getState().companies.companies.length > 0 ?
                        <Menu.Item key="9" className={props.actual_tab==="Trainings"?"selected":""}>
                            <Link to={{pathname:"/ctrainings"}}>
                                <FundProjectionScreenOutlined />
                            </Link>
                        </Menu.Item>
                        :
                        <Menu.Item key="14" className={props.actual_tab==="Trainings"?"selected":""}>
                            <Link to={{pathname:"/utrainings"}}>
                                <FundProjectionScreenOutlined />
                            </Link>
                        </Menu.Item>
                        }


                    </>}

                {/* Profile */}
                <Menu.Item key="15" className="profile_menu">
                    <Link to={{ pathname: "/profile" }}>
                        {store.getState().user.profile.avatar_url !== undefined ? <Avatar src={store.getState().user.profile.avatar_url} /> : <Avatar>{store.getState().user.profile.last_name.substring(0, 1)} {store.getState().user.profile.first_name.substring(0, 1)}</Avatar>}
                    </Link>
                </Menu.Item>

                {/* Bouton de déconnection */}
                <Menu.Item key="16" className="logout_button" onClick={Disconnect}>
                    <LogoutOutlined />
                </Menu.Item>

                {/* Cookies */}
                <CookieConsent
                    style={{height:"60px", alignContent:"center"}}
                    buttonText="Accepter"
                    buttonStyle={{ backgroundColor: "#FCAE03", fontSize: "13px", width: "150px", height:"40px" }}
                >Cette plateforme utilise des cookies nécessaires au fonctionnement de l'application</CookieConsent>
            </Menu>
        </Space>
    ):<Redirect to='/'/>
}