export const Types = {
    LOGIN: 'LOGIN',
    REGISTER : 'REGISTER',
    LOGOUT: 'LOGOUT',
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_TRAININGS: 'UPDATE_TRAININGS',
    UPDATE_TRAINERS: 'UPDATE_TRAINERS',
    UPDATE_USERS: 'UPDATE_USERS',
    UPDATE_COMPANIES: 'UPDATE_COMPANIES',
    UPDATE_CERTIFICATES: 'UPDATE_CERTIFICATES',
    FETCH_USER_TRAININGS: 'FETCH_USER_TRAININGS',
    UPDATE_SELECTED: 'UPDATE_SELECTED'
}

export const ActionCreators = {
  
    updateProfile: (user) => ({ type: Types.UPDATE_USER, payload: { user } }),
  
    login: (user) => ({ type: Types.LOGIN, payload: { user } }),

    register : (user, password) => ({type : Types.REGISTER, payload : { "user": user, "password" : password }}),

    logout: () => ({ type: Types.LOGOUT }),

    fetchUserTrainings: (trainings) => ({ type: Types.FETCH_USER_TRAININGS, payload: {trainings} }),

    updateTrainings: (trainings) => ({ type: Types.UPDATE_TRAININGS, payload: { trainings } }),

    updateTrainers: (trainers) => ({ type: Types.UPDATE_TRAINERS, payload: { trainers } }),

    updateUsers: (users) => ({ type: Types.UPDATE_USERS, payload: { users } }),

    updateCompanies: (companies) => ({ type: Types.UPDATE_COMPANIES, payload: { companies } }),

    updateCertificates: (certificates) => ({ type: Types.UPDATE_CERTIFICATES, payload: { certificates }}),

    updateSelected: (selected_id) => ({ type: Types.UPDATE_SELECTED, payload: { selected_id } })
}

export default ActionCreators;