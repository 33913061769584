//Import des composants utiles
import React from 'react';
import API from '../../api';
import store from '../../Store/Store';
import Navbar from '../../components/Navbar';
import axios from 'axios';

//Import des composants de design
import { Layout, Input, List, Row, Col, Space, Empty, Menu } from 'antd';
import { CompanyDetailsCard } from '../../components/LeftPanel';
import { CompanyEmployeeDetailCard } from '../../components/RightPanel'
import { AddAnEmployee } from '../../components/Modal'
import { GoBackTitle, EmployeeCard, Loading, CompanyInvoicesCard} from '../../components/Component'
const { Header, Sider, Content } = Layout;
const { Search } = Input;

//Page de l'entreprise
class Company extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            id: store.getState().user.selected,
            company: undefined,
            invoices: [],
            employees: [],
            searchText: "",
            selected: 0,
            current: "employees"
        }

        //Génération d'un token d'arrêt des appels
        this.source = axios.CancelToken.source();

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;

        //Binding
        this.loadCompanyDetails = this.loadCompanyDetails.bind(this);
        this.loadCompanyEmployees = this.loadCompanyEmployees.bind(this);
        this.loadCompanyFiles = this.loadCompanyFiles.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount(){
        this._isMounted = true;
        let config = { cancelToken: this.source.token}

        this.loadCompanyDetails(config);
        this.loadCompanyEmployees(config); 
        this.loadCompanyFiles(config);
    }

    componentWillUnmount(){
        this._isMounted = false;
        this.source.cancel("Unmounted Component");
    }

    //Chargement des détails de l'entreprise
    loadCompanyDetails(config){
        API.get('api/companies/'+this.state.id, config)
        .then((res) => {
            if(this._isMounted)
                this.setState({company: res.data})
        }).catch((err) => { 
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Chargement de la liste d'employés de l'entreprise
    loadCompanyEmployees(config){
        API.get('api/companies/'+this.state.id+'/users', config)
        .then((res) => {
            if(this._isMounted)
                if(this.state.selected == 0)
                    this.setState({employees: res.data,
                    selected: res.data[0].id
                })
                else this.setState({employees: res.data})
        }).catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Chargement des fichiers de l'entreprise
    loadCompanyFiles(config){
        API.get('api/companies/'+this.state.id+'/files?type=FILE_INVOICE', config)
        .then((res) => {
            if(this._isMounted)
                this.setState({invoices: res.data})
        }).catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Prise en charge du changement de menu
    handleClick(event) {
        this.setState({ current: event.key })
    };

    //Prise en charge du changement de sélection
    handleSelect(value){
        if(this._isMounted)
            this.setState({selected: value})
    }

    render(){
        const onSearch = (value) => this.setState({searchText: value});
        const onSearchChange = (event) => this.setState({searchText: event.target.value});
        const selectedUser = this.state.employees.find(emp => emp.id == this.state.selected);
        
        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar actual_tab={"Company"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <GoBackTitle route="/aclients" title="Revenir aux clients"/>
                    </Header>

                    {this.state.company !== undefined ?
                    <Content className="app-content">
                        <Row gutter={[48, 48]}>
                            <Col span={6}>
                                <Space direction="vertical" size="middle">
                                    <CompanyDetailsCard company={this.state.company} isAdmin={true} isCompanyAdmin={true} refresh_handler={this.loadCompanyDetails}/>
                                </Space>
                            </Col>                        
                            <Col span={18}>
                                {/* NavMenu */}
                                <Row justify="space-between">
                                    <Menu onClick={(e) => this.handleClick(e)} mode="horizontal" style={{flex:'auto'}} selectedKeys={this.state.current}>
                                        <Menu.Item key="employees">
                                            Employés
                                        </Menu.Item>
                                        <Menu.Item key="invoices">
                                            Factures
                                        </Menu.Item>                               
                                    </Menu>  
                                    {this.state.current==="employees" &&
                                    <Col style={{display:'flex', alignItems:'end', margin:'0 25px'}}>
                                        <Search placeholder="Rechercher un employé" onChange={onSearchChange} onSearch={onSearch} enterButton />
                                        <AddAnEmployee company_id={this.state.id} refresh_handler={this.loadCompanyEmployees} />
                                    </Col>
                                    }
                                </Row>
                                {this.state.current==="employees" && 
                                <>
                                    <Row gutter={[48, 48]} style={{paddingTop:20}}>
                                        <Col id="training_info" span={14}>
                                            {/* Employés */}
                                            <List
                                                pagination={{
                                                    simple:true,
                                                    pageSize: 6,                                  
                                                }}
                                                dataSource={this.state.employees.filter(employee => (employee.first_name + " " + employee.last_name).toLowerCase().includes(this.state.searchText.toLowerCase())).sort((a,b) => (a.last_name > b.last_name) ? 1 : ((b.last_name > a.last_name) ? -1 : 0))}
                                                renderItem={item => (
                                                    <EmployeeCard refresh_handler={this.loadCompanyEmployees} key={item.id} selection_handler={() => this.handleSelect(item.id)} selected={this.state.selected == item.id?true:false} employee={item}/>
                                                    )
                                                }
                                                locale={{emptyText:<Empty description="Aucun employé trouvé" image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                                            />
                                        </Col>

                                        <Col span={10}>
                                            <Content id="training_calendar">
                                                {/* Information de l'employé*/} 
                                                {selectedUser &&
                                                    <CompanyEmployeeDetailCard refresh_handler={(this.loadCompanyEmployees)} employee={selectedUser} isAdmin={true}/>
                                                }
                                            </Content>
                                        </Col>
                                    </Row>
                                </>}
                                {this.state.current==="invoices" && 
                                <>
                                    <Row gutter={[48, 48]} style={{paddingTop:20}}>
                                        <Col id="training_info" style={{width:"100%"}}>
                                            {/* Factures */}
                                            <List
                                                grid={{
                                                    gutter: 16,
                                                    xs: 1,
                                                    sm: 1,
                                                    md: 1,
                                                    lg: 2,
                                                    xl: 2,
                                                    xxl: 2,
                                                }}
                                                pagination={{
                                                    pageSize: 18,
                                                    hideOnSinglePage:true,
                                                }}
                                                dataSource={this.state.invoices}
                                                renderItem={item => (
                                                    <CompanyInvoicesCard key={item.id} invoice={item} />
                                                    )
                                                }
                                                locale={{emptyText:<Empty description="Aucune facture trouvé" image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                                            />
                                        </Col>
                                    </Row>
                                </>}
                            </Col>
                        </Row>
                    </Content>
                    :<Loading />}
                </Layout>
            </Layout>
        );
    }
}

export default Company;