//Import des composants utiles
import React from 'react';
import API from '../../api';
import { ActionCreators } from '../../Store/Action'
import store from '../../Store/Store';
import Navbar from '../../components/Navbar';
import axios from 'axios';

//Import des composants de design
import { AddANews, EditNews, RemoveNews, RemovePlace, RemoveAdmin, AddAnAdmin, ChangePlaceDetails } from '../../components/Modal'
import { TeamOutlined } from '@ant-design/icons';
import { Typography, Layout, Card, Input, Row, Col, List, Empty, Space, Divider, Menu } from 'antd';
const { Title, Paragraph,Text } = Typography;
const { Header, Sider, Content } = Layout;
const { Search } = Input;
const { Meta } = Card;

//Carte des détails de la formation
export function NewsBigCard(props){
    return (
        <Card
            actions={[<RemoveNews refresh_handler={props.refresh_handler} news={props.news}/>,<EditNews refresh_handler={props.refresh_handler} news={props.news}/>]}
        >
            <Title level={5}>{props.news.title}</Title>
            <Paragraph>{props.news.data}</Paragraph>
        </Card>
    );
}

//Carte des lieux de formation !
export const PlaceBigCard = ({place, refresh_handler}) => {
    let description = 
        <>
            <Space direction='vertical' size={0}>
                <Text type="secondary">{place.street+" "+ (place.street_box==null?"":place.street_box)}</Text>
                <Text type="secondary">{place.zip_code+" "+place.locality}</Text>
            </Space>
            <Divider>            
                <Space align="baseline">
                    <Text strong style={{verticalAlign: 'middle'}}>{place.capacity}</Text>
                    <TeamOutlined style={{ fontSize: 16, verticalAlign: 'middle' }}/>
                </Space>
            </Divider>
        </>

    return (
        <Card
            actions={[
            <RemovePlace place={place}/>,
            <ChangePlaceDetails refresh_handler={refresh_handler} place={place}/>
            ]}
        >
            <Meta
                title={place.name}
                description={description}
            />
            <Space direction="vertical">
                <Text type="secondary">{place.description}</Text>
            </Space>
        </Card>
    );
}

//Carte des admins
export function AdminBigCard(props){
    return (
        <Card
            actions={[<RemoveAdmin refresh_handler={props.refresh_handler} user={props.user}/>]}
        >
            <Meta
                title={props.user.last_name}
                description={props.user.first_name}
            />
        </Card>
    );
}

//Page de listing des formations
class News extends React.Component{
    constructor(props){
        super(props)

        //Récupération des informations dans le store
        this.state = {
            news: [],
            admins: [],
            searchText: "",
            current: "news"
        }

        //Génération d'un token d'arrêt des appels
        this.source = axios.CancelToken.source();
        this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
        this.handleOnSearch = this.handleOnSearch.bind(this);
        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;
        let config = { cancelToken: this.source.token}

        this.loadNewsList(config);
        this.loadAdminList(config);
    }

    componentWillUnmount(){
        this._isMounted = false;
        this.source.cancel("Unmounted Component");
    }

    //Chargement des actualités
    loadNewsList(config){
        API.get('api/news', config)
        .then((res) => {
            if(this._isMounted){
                this.setState({news: res.data});
            }
        }).catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        }) 
    }

    //Chargement des actualités
    loadAdminList(config){
        API.get('api/users?role=ROLE_ADMIN', config)
        .then((res) => {
            if(this._isMounted){
                this.setState({admins: res.data});
            }
        }).catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        }) 
    }
    
    //Prise en charge du changement de menu
    handleClick(event) {
        this.setState({ current: event.key })
    };

    //Recherche en direct
    handleOnChangeSearch(event){
        this.setState({searchText: event.target.value});
    }

    //Recherche Hit Enter
    handleOnSearch(value){
        this.setState({searchText: value});
    }

    render(){
        //Filtrage des actualités
        const newsToDisplay = this.state.news.filter(news => {
            return news.title.toLowerCase().includes(this.state.searchText.toLowerCase())
        });

        //Filtrage des administrateurs
        const adminsToDisplay = this.state.admins.filter(admin => {
            return (admin.first_name + " " + admin.last_name).toLowerCase().includes(this.state.searchText.toLowerCase())
        });

        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar actual_tab={"Parameters"}/>
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Title level={1}>Configuration de Formatrans</Title>
                    </Header>
                    <Content className="app-content">  
                        <Row justify="space-between">
                            <Menu onClick={(e) => this.handleClick(e)} mode="horizontal" style={{flex:'auto'}} selectedKeys={this.state.current}>
                                <Menu.Item key="news">
                                    Actualités
                                </Menu.Item>
                                <Menu.Item key="admins">
                                    Administrateurs
                                </Menu.Item>
                            </Menu>    
                              
                            <Col style={{display:'flex', alignItems:'end', margin:'0 25px'}}>
                                <Search placeholder="Rechercher un élément" onSearch={this.handleOnSearch} onChange={this.handleOnChangeSearch} enterButton/>
                                {this.state.current==="news"?<AddANews refresh_handler={this.loadNewsList.bind(this)} />:<AddAnAdmin refresh_handler={this.loadAdminList.bind(this)}/>}
                            </Col>
                        </Row>    
                        <Row style={{paddingTop:20}}>             
                            {/* Liste des actualitées */}
                            {this.state.current==="news"?<List
                                grid={{
                                    gutter: 32,
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                    lg: 4,
                                    xl: 4,
                                    xxl: 4,
                                }}
                                pagination={{
                                    pageSize: 8,
                                    hideOnSinglePage:true,
                                }}
                                dataSource={newsToDisplay}
                                renderItem={item => (
                                    <NewsBigCard key={item.id} refresh_handler={this.loadNewsList.bind(this)} news={item} />
                                    )
                                }
                                locale={{emptyText:<Empty description="Aucune actualité trouvée" image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                            />:
                            <List
                                grid={{
                                    gutter: 32,
                                    xs: 1,
                                    sm: 2,
                                    md: 4,
                                    lg: 4,
                                    xl: 4,
                                    xxl: 6,
                                }}
                                pagination={{
                                    pageSize: 8,
                                    hideOnSinglePage:true,
                                }}
                                dataSource={adminsToDisplay}
                                renderItem={item => (
                                    <AdminBigCard key={item.id} refresh_handler={this.loadAdminList.bind(this)} user={item} />
                                    )
                                }
                                locale={{emptyText:<Empty description="Aucun administrateur trouvé" image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                            />}
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        )
    }
}

export default News;