import axios from 'axios';
import store from './Store/Store';
import { ActionCreators } from './Store/Action';

//Communication avec l'API
const instance = axios.create({
  baseURL: `/`,
  withCredentials: true,
  headers: {
    'X-Requested-With' : 'XMLHttpRequest',
    'Accept' : 'application/json'
  }
});

instance.interceptors.response.use((response) => {
  return response
}, (error) => {
  console.log("ERROR INTERCEPTOR");
  if (error.response.status === 401) {
    store.dispatch(ActionCreators.logout());
  }
  else if (error.response.status === 419) {
    store.dispatch(ActionCreators.logout());
    window.location.reload();
  }
  
  return Promise.reject(error);
});

export default instance;