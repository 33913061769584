//Import des composants utiles
import React from 'react';
import API from '../../api';
import store from '../../Store/Store';
import Navbar from '../../components/Navbar';
import axios from 'axios';
import moment from 'moment';
import {sortChronologicaly} from '../../utils'
import { WarningOutlined } from '@ant-design/icons';

//Import des composants de design
import { Typography, Layout, Row, Col, List, Empty, Button, Checkbox, message } from 'antd';
import { SmallTrainingCard, SmallDocumentCard } from '../../components/Component'
const { Title } = Typography;
const { Header, Sider, Content } = Layout;

//Tableau de bord de l'utilisateur
class Dashboard extends React.Component{
    constructor(props){
        super(props)

        //Récupération des informations dans le store
        this.state = {
            user: store.getState().user,
            trainings: [],
            invoices: [],
            reminders:[],
            userExpiredCertificates: [],
            filteredUserExpiredCertificates: [],
            expiratedCertificatesToRemindFilterState : true
        }

        //Génération d'un token d'arrêt des appels
        this.source = axios.CancelToken.source();

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
        this.handleExportDatabase = this.handleExportDatabase.bind(this);
    }

    componentDidMount(){
        this._isMounted = true;
        
        this.refreshTrainings();
        this.refreshInvoices();
        this.refreshUserCertificates();
    }

    componentWillUnmount(){
        this._isMounted = false;
        this.source.cancel("Unmounted Component");
    }

    handleExportDatabase() {
        message.loading('Export en cours...');
        API.get('api/export').then(response => {
            API.get(response.data.url, {
                responseType: 'blob'
            }).then(res => {
                const fileDownload = require('js-file-download');
                let fileTitle = response.data.name;
                fileDownload(res.data, fileTitle);
                message.success('Export terminé !');
            });
        });
    }

    refreshUserCertificates() {
       
        Promise.all([API.get('/api/certificates'), API.get('/api/certificate-users'),API.get('/api/users')]).then(response => {
            const certificates = response[0].data;
            const certificateUsers = response[1].data;
            const users = response[2].data;
            let expiredCertificates = certificateUsers.filter(certificateUser => {
                const certificate = certificates.find(cert => cert.id === certificateUser.certificate_id);
                if (!certificate.validity) return false;
                certificateUser.certificate = certificate;
                const now = moment();
                const expirationDate = moment.utc(certificateUser.granted).add(moment.duration(certificate.validity));
                certificateUser.expirationDate = expirationDate;
                certificateUser.type = "certificate_user"
                return (now.isBetween(moment.utc(expirationDate).subtract(1, 'year'), expirationDate));
            });

            let expiredUserCertificate = users.filter((user)=>{
                if(user.adr_validity!=null && user.adr_category!=null){
                    const now = moment();
                    const expirationDate = moment.utc(user.adr_validity);
                    return (now.isBetween(moment.utc(expirationDate).subtract(1, 'year'), expirationDate));

                }
            })
            let newCertificatesExpired = expiredUserCertificate.map((user)=>{
                let expiredCertificate = {                                        
                    certificate : {
                        name : user.adr_category                        
                    },
                    id : user.id,
                    expirationDate :  moment.utc(user.adr_validity),
                    mail_send : user.mail_send,
                    user_id : user.id,
                    type : "user"

                }
                return expiredCertificate;
            })
            expiredCertificates.push(...newCertificatesExpired)
            expiredCertificates = expiredCertificates.sort(function(a,b){
                return Date.parse(a.expirationDate) - Date.parse(b.expirationDate);
              });
            
            expiredCertificates = expiredCertificates.map(exptCert => {
                exptCert.user = users.find(user => exptCert.user_id === user.id);
                exptCert.company = exptCert.user.companies;
                return exptCert;
            });

            this.setState({userExpiredCertificates: expiredCertificates});
            this.setState({filteredExpiredCertificates:expiredCertificates});
            this.filterExpiratedCertificatesToRemindHandle();
        });
    }

    //Récupération des prochaines formations
    refreshTrainings(){
        let config = { cancelToken: this.source.token}

        var todayDate = new Date().toUTCString();

        API.get('api/training-occurences?start_date='+todayDate, config)
        .then(trainings_data => {
            let training_occ = []
            
            trainings_data.data.forEach(async (occurence) => {
                let earliestDurations;
                for (let duration of occurence.durations){
                    if(moment(duration.start_date).isAfter(moment(todayDate)) && earliestDurations === undefined){
                        earliestDurations = duration;
                    }else if(moment(duration.start_date).isAfter(moment(todayDate)) && moment(duration.start_date).isBefore(earliestDurations.start_date)){
                        earliestDurations = duration;
                    }
                };
                
                if(earliestDurations !== undefined){
                    let occ = {
                        id: occurence.id,
                        training_id:occurence.training_id,
                        name: "",
                        place:occurence.place.name,
                        start_date:earliestDurations.start_date,
                        date : moment(earliestDurations.start_date).utc().format('LL') +' '+ moment(earliestDurations.start_date).utc().format('LT') + " - " + moment(earliestDurations.end_date).utc().format('LT')
                    }

                    let error = false;

                    await API.get('api/trainings/'+ occurence.training_id, config)
                    .then(train_data => {
                        occ['name'] = train_data.data.name;
                    }).catch(err => { error = true })

                    if(!error && this._isMounted){
                        training_occ.push(occ)
                        training_occ = sortChronologicaly(training_occ,"start_date")
                        this.setState({trainings : training_occ});
                    }
                }
            });
        }).catch(err => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })  
    }

    //Récupération des factures non terminé
    refreshInvoices(){
        API.get('api/files?type=FILE_INVOICE')
        .then((res) => {
            if(this._isMounted){
                this.setState({invoices : res.data.filter(file => file.type === "FILE_INVOICE" && file.metadatas.sent === false)});
            }
        }).catch(err => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Mise à jour des rappels de certificats
    setRemindSend(certificate_id, mail_send,type){
        if(type=="user"){

            API.put(`api/users/${certificate_id}`, {
                'mail_send' : !mail_send,
            }).then((res) => {
                let expiredCertificates = this.state.userExpiredCertificates;
                expiredCertificates[expiredCertificates.findIndex(cert => cert.id === certificate_id)].mail_send = res.data.mail_send;
                if(this._isMounted){
                    this.setState({userExpiredCertificates : expiredCertificates});
                }
            })
        }
        else if(type=="certificate_user"){
            API.put(`api/certificate-users/${certificate_id}`, {
                'mail_send' : !mail_send,
            }).then((res) => {
                let expiredCertificates = this.state.userExpiredCertificates;
                expiredCertificates[expiredCertificates.findIndex(cert => cert.id === certificate_id)].mail_send = res.data.mail_send;
                if(this._isMounted){
                    this.setState({userExpiredCertificates : expiredCertificates});
                }
            })

        }
    }
    //Filtrer les certificats qui qui n'ont pas fait l'objet d'un rappel. Sinon affiche tout.
    filterExpiratedCertificatesToRemindHandle(){
        let filteredExpiredCertificates = [];
        if(this.state.expiratedCertificatesToRemindFilterState==true){
            filteredExpiredCertificates = this.state.userExpiredCertificates;
        }else{
            filteredExpiredCertificates = this.state.userExpiredCertificates.filter(certificateUser => {                
                return certificateUser.mail_send == false;
            });
        }
        
        this.setState({expiratedCertificatesToRemindFilterState : !this.state.expiratedCertificatesToRemindFilterState});
        this.setState({filteredExpiredCertificates : filteredExpiredCertificates});

    }
    render(){
        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar actual_tab={"Dashboard"}/> 
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header style={{display:'flex', justifyContent:'space-between', flexDirection:'row'}}>
                        <Title level={1}>Bonjour {this.state.user.profile.first_name},</Title>
                        <Button onClick={this.handleExportDatabase} type="primary">Exporter la base de donnée</Button>
                    </Header>
                    <Content className="app-content">                        
                        <Row gutter={[48, 16]}>
                            {/* Formations */}
                            <Col span={8}>
                                <Title level={5}>Prochaines formations</Title>
                                <List
                                    pagination={{
                                        pageSize: 5,
                                        hideOnSinglePage:true,
                                        position:'bottom',
                                        size:'small'
                                    }}
                                    dataSource={this.state.trainings}
                                    renderItem={item => (
                                        <SmallTrainingCard key={item.id} data={item} isAdmin={true} isUser={false}/>
                                        )
                                    }
                                    locale={{emptyText:<Empty description="Aucune formation trouvée" image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}
                                />                               
                            </Col> 

                            {/* Certificats */}
                            <Col span={8}>
                                <Title level={5}>Factures en attentes</Title>
                                <List
                                    pagination={{
                                        pageSize: 10,
                                        hideOnSinglePage:true,
                                    }}
                                    dataSource={this.state.invoices.slice(0, 3)}
                                    renderItem={item => (
                                        <SmallDocumentCard key={item.id} data={item} />
                                        )
                                    }
                                    locale={{emptyText:<Empty description="Aucune facture en attente" image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                                />                                
                            </Col>
                            <Col>
                                <Title level={5}>
                                    Expiration certificats utilisateurs
                                </Title>

                                <Checkbox onChange={() => this.filterExpiratedCertificatesToRemindHandle()} checked={this.state.expiratedCertificatesToRemindFilterState}>À rapeller</Checkbox>
                                <List
                                    pagination={{
                                        pageSize: 10,
                                        hideOnSinglePage:true,
                                    }}
                                    dataSource={this.state.filteredExpiredCertificates}
                                    renderItem={item => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={
                                                    <WarningOutlined />
                                                }
                                                title={`${item.user.first_name} ${item.user.last_name} - ${item.company&&item.company.length>0?item.company[0].company_name:'Aucune'}`}
                                                description={`${item.certificate.name} expire le ${item.expirationDate.format('DD/MM/YYYY')}`}
                                            />
                                            <Checkbox onChange={() => this.setRemindSend(item.id, item.mail_send,item.type)} checked={item.mail_send}>Rappel</Checkbox>
                                        </List.Item>
                                        )
                                    }
                                    locale={{emptyText:<Empty description="Aucun certificat ne va expirer prochainement" image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                                />  
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default Dashboard;