import { Types } from '../Action';

//Etat initial de l'utilisateur
const initialState = {
  profile: {},
  trainings: [],
  selected: null,
  fromRegister: false,
  isLogged: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.LOGIN:
      return {
        ...state,
        profile: action.payload.user,
        isLogged: true,
        fromRegister: false,
      }

    case Types.REGISTER:
      return{
        ...state,
        profile : action.payload.user,
        isLogged : false,
        fromRegister : true,
      }

    case Types.LOGOUT:
      window.localStorage.clear();
      return {
        ...state,
        profile: {},
        trainings: [],
        selected: null,
        isLogged: false,
        fromRegister: false,
      }

    case Types.FETCH_USER_TRAININGS:
      return {
        ...state,
        trainings: action.payload.trainings,
      }

    case Types.UPDATE_USER:
      return {
        ...state,
        profile: action.payload.user,
      }

    case Types.UPDATE_SELECTED:
      return {
        ...state,
        selected: action.payload.selected_id,
      }

    default:
      return state;
  }
}

export default reducer;