//Import des composants utiles
import React from 'react';
import store from '../Store/Store';
import API from '../api';
import Navbar from '../components/Navbar';
import moment from 'moment';
import axios from 'axios';

//Import des composants de design
import { ShortDescription } from '../components/Component';
import { Typography, Layout, Row, Col, List, Empty, Card, Divider } from 'antd';
import { ProfileCard } from '../components/LeftPanel';
import { TrainingCard } from '../components/MiddlePanel';
const { Title } = Typography;
const { Header, Sider, Content } = Layout;


//Page profile de l'utilisateur
class ProfilePage extends React.Component{
    constructor(props){
        super(props)

        //Récupération des informations dans le store
        this.state = {
            trainings: [],
            certificates: [],
            documents: [],
        }

        //Génération d'un token d'arrêt des appels
        this.source = axios.CancelToken.source();

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    componentDidMount(){
        this._isMounted = true;

        if(store.getState().user.profile.role !== "ROLE_ADMIN" && store.getState().user.profile.role !== "ROLE_TRAINER"){
            this.refreshTrainings()
            this.refreshCertificates()
            this.refreshDocuments()
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
        this.source.cancel("Unmounted Component");
    }

    //Récupération des certificats
    refreshCertificates(){
        API.get(`/api/users/${store.getState().user.profile.id}/certificates`)
        .then((res) => {            
            this.setState({certificates: res.data});
        });
    }

    //Récupération des certificats
    refreshDocuments(){
        API.get(`/api/users/${store.getState().user.profile.id}/files`)
        .then((res) => {            
            this.setState({certificates: res.data});
        });
    }

    //Récupération des occurences de formation réalisés
    async refreshTrainings(){
        API.get(`/api/users/${store.getState().user.profile.id}/training-occurences`)
        .then((res) => {
            let trainings = res.data;

            const promises = [];

            promises.push(Promise.all([...new Set(trainings.map((tr_occ) => tr_occ.training_id))].map(training_id => API.get('/api/trainings/'+training_id))).then(res => {
                const training = res.map(o => o.data);
                trainings.forEach(occ => {
                    occ.training = training.find(t => t.id === occ.training_id);
                });
            }));

            promises.push(...trainings.map((occ) => {
                return API.get('/api/training-occurences/'+occ.id+'/durations').then((res) => {
                    occ.durations = res.data;
                    occ.lastDurationDate = moment.max([].concat(...occ.durations.map((dur) => [moment(dur.start_date), moment(dur.end_date)]))).toISOString();
                });
            }));

            Promise.all(promises).then(() =>{
                this.setState({trainings: trainings});
            });
        });
    }

    render(){

        //Liste des formations effectuées
        const listDoneTrainingOccurences = () => {
            return this.state.trainings.filter((tr_occ) => (
                tr_occ.durations.every((duration) => 
                    moment(duration.end_date).isBefore(new Date().now, 'day'))
                )
            ); 
        }

        return (
            <Layout>
                <Sider width={80} className="main_nav">
                    <Navbar />
                </Sider>
                <Layout hasSider={false} className="main-container">
                    <Header>
                        <Title level={1}>Mon profil</Title>
                    </Header>
                    <Content className="app-content">
                        <Row gutter={[48, 48]}>
                            {/* LeftPanel */}
                            <Col span={8}>
                                <ProfileCard />
                            </Col>
                            
                            {store.getState().user.profile.role != "ROLE_ADMIN" && store.getState().user.profile.role != "ROLE_TRAINER" &&
                            <Col span={8}>
                                <Title level={4}>Formations réalisées</Title>
                                    {/* MiddlePanel */}
                                    <List
                                        id="user_training"
                                        pagination={{
                                            pageSize: 8,
                                            hideOnSinglePage:true,
                                        }}
                                        dataSource={listDoneTrainingOccurences()}
                                        renderItem={training_occurence => (
                                            <TrainingCard trainingOccurence={training_occurence} isDone={false} />
                                            )
                                        }
                                        locale={{emptyText:<Empty description="Pas de formation réalisée" image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                                    />
                            </Col>}

                                    
                            {/* Certificats */}
                            {store.getState().user.profile.role != "ROLE_ADMIN" && store.getState().user.profile.role != "ROLE_TRAINER" && store.getState().user.profile.role != "ROLE_COMPANY"&&
                            <Col span={8}>                                 
                                <Card>
                                    <Title level={5}>Mes certificats</Title>
                                    <Divider />
                                    <List
                                        size="small"
                                        className="cardList"
                                        dataSource={this.state.certificates}
                                        renderItem={item => (
                                            <ShortDescription key={item.id} type="certificate" title={item.name} date={new Date(item.pivot.granted)}/>
                                        )}
                                        pagination={{
                                            pageSize: 3,
                                            hideOnSinglePage:true,
                                            simple:true,
                                        }}
                                        locale={{emptyText:<Empty description="Vous n'avez pas encore de certificat" image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                                    />
                                </Card>
                                <Card>
                                    <Title level={5}>Factures</Title>
                                    <Divider />
                                    <List
                                        size="small"
                                        className="cardList"
                                        dataSource={this.state.documents}
                                        renderItem={item => (
                                            <ShortDescription key={item.id} type="doc" title={item.name} date={new Date(item.pivot.granted)}/>
                                        )}
                                        pagination={{
                                            pageSize: 3,
                                            hideOnSinglePage:true,
                                            simple:true,
                                        }}
                                        locale={{emptyText:<Empty description="Vous n'avez pas encore de facture" image={Empty.PRESENTED_IMAGE_SIMPLE}/> }}
                                    />
                                </Card>
                            </Col>
                            }

                        </Row>
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default ProfilePage;