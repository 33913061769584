//Import des composants utiles
import React, { useEffect, useState } from 'react';
import { ActionCreators } from '../Store/Action'
import { useHistory } from "react-router-dom";
import store from '../Store/Store';
import API from '../api';
import moment from 'moment';
import { ChangeCompanyDetails, CreateACompany, EditPassword, EditAvatar, ChangeUserDetails, ChangeTrainingDetails, ChangeTrainerDetails } from './Modal'

//Import des composants de design
import { MailOutlined, FundProjectionScreenOutlined, HomeOutlined, FieldNumberOutlined, EnvironmentOutlined, UserOutlined, MobileOutlined, RollbackOutlined } from '@ant-design/icons';
import { Card, Avatar, Button, Typography, Divider, message, Row, Col, Space, Empty, List, Popconfirm, Menu, Badge, Tabs  } from 'antd';
const { Title, Paragraph, Text } = Typography;
const { Meta } = Card;
const { TabPane } = Tabs;

//Carte de profil d'un utilisateur !
export const ProfileCard = ({}) => {
    const [user, setUser] = useState({});
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        loadUserInfo();
        loadUserCompanies();
    }, [])

    let profil = <Space direction="vertical" style={{rowGap:15}}>
                
                {user.national_number != null &&
                    <Badge count={<UserOutlined />} text={user.national_number} offset={[0,0]}/>
                }

                {user.birth_date != null &&
                    <Badge count={<UserOutlined />} text={moment(user.birth_date).format('LL')} offset={[0,0]}/>
                }

                <Badge count={<MailOutlined />} text={user.email} offset={[0,0]}/>
                {user.email_verified_at == null?<Button type="text" onClick={() => sendConfirmationMail()}>Confirmer l'adresse mail</Button>:<></>}

                {user.vat_number != null &&
                    <Badge count={<MailOutlined />} text={user.vat_number} offset={[0,0]}/>
                }

                {user.mobile_phone_number != null &&
                    <Badge count={<MobileOutlined />} text={user.mobile_phone_number} offset={[0,0]}/>
                }
                
                {user.street != null & user.street_box != null ?
                    <Badge count={<EnvironmentOutlined />} text={user.street+", "+user.street_number+" - "+user.street_box} offset={[0,0]}/>
                    :
                    <Badge count={<EnvironmentOutlined />} text={user.street+", "+user.street_number} offset={[0,0]}/>
                }

                {user.zip_code != null &&
                    <Badge count={<EnvironmentOutlined />} text={user.zip_code + '-' + user.locality} offset={[0,0]}/> 
                }

                <Row justify="center">
                    <EditPassword />
                </Row>
                <Row justify="center">
                    <CreateACompany user={user} />
                    <ChangeUserDetails user={user} refresh_handler={() => loadUserInfo()}/>
                </Row>
            </Space>;

    //Chargement des informations de l'utilisateur
    const loadUserInfo = () => {
        API.get('api/users/'+store.getState().user.profile.id)
        .then((res) => {
                store.dispatch(ActionCreators.updateProfile(res.data));
                setUser(res.data);
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }

    //Chargement des entreprises de l'utilisateur
    const loadUserCompanies = () => {
        API.get('api/users/'+store.getState().user.profile.id+'/companies')
        .then((res) => {
            setCompanies(res.data)
        })
        .catch((err) => {
            if(err.response != null && err.response.status == 401){
                store.dispatch(ActionCreators.logout());
            }
        })
    }


    //Envoie d'un mail de confirmation de l'adresse email
    const sendConfirmationMail = () => {
        API.post('/email/verification-notification')
        .then((res) => {
            message.success("Email de confirmation envoyé !")
        })
        .catch((err) => {
            message.error("Erreur lors de l'envoi du mail !")
        })
    }

    return (
        <Card cover={<EditAvatar user={user} refresh_handler={() => loadUserInfo}/>}>
            <Meta title={user.last_name + " " + user.first_name} description={user.role==="ROLE_ADMIN"?"Administrateur":user.role==="ROLE_TRAINER"?"Formateur":"Utilisateur"} />
            <Row justify="center">
                <Col span={18}>
                    {user.role == "ROLE_USER"?
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Informations" key="1">
                                {profil}
                            </TabPane>
                            <TabPane tab="Entreprises" key="2">
                                <List className="cardList" size="small" dataSource={companies} renderItem={company => (
                                    <List.Item>
                                        <List.Item.Meta 
                                            avatar={<FundProjectionScreenOutlined />}
                                            title={company.company_name}
                                            description={company.pivot.role==="ROLE_ADMIN"?"Administrateur":"Chauffeur"}
                                        />
                                    </List.Item>
                                )}
                                    pagination={{
                                        pageSize: 4,
                                        hideOnSinglePage:true,
                                        simple:true,
                                        position:'top',
                                    }}
                                    locale={{emptyText:<Empty description="Aucune entreprise" image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}
                                >
                                </List>
                            </TabPane>
                        </Tabs>
                        :
                        <>{profil}</>
                    }
                </Col>
            </Row>          
        </Card>
    )
}               

//Carte du profil utilisateur vu par l'admin !
export const AdminUserProfileCard = ({user, companies, refresh_handler}) => {
    const [current, setCurrent] = useState("informations");
    let history = useHistory();

    const deleteUser = () => {
        API.delete(`api/users/${user.id}`).then(()=>{
            history.push('/aclients')
            message.success("Suppression du client !");
        }).catch((err)=>{
            message.success("Erreur lors de la suppression du client !");
        })
    }

    //Prise en charge du changement de menu
    const handleClick = (event) => {
        setCurrent(event.key);
    };

    const inviteUserToJoinThePlateform = () => {
        API.put('api/users/'+user.id, {
            'invited' : true,
        }).then(user_data => {
            refresh_handler(user_data.data);
            message.success('Le mail à bien été envoyé');
        }).catch((err) => { source.cancel("Request Error"); });
    }

    return(
        <Card cover={<EditAvatar user={user} refresh_handler={() => refresh_handler}/>}>
            <Meta title={user.last_name} description={user.first_name} />
            <Menu onClick={(e) => handleClick(e)} mode="horizontal" selectedKeys={current}>
                <Menu.Item key="informations">
                    Informations
                </Menu.Item>
                <Menu.Item key="companies">
                    Entreprises
                </Menu.Item>
            </Menu>
            {current==="informations"?
            <Space direction="vertical" align='center' style={{rowGap:15, marginTop:15}}>
                
                {user.national_number != null &&
                    <Badge count={<UserOutlined />} text={user.national_number} offset={[0,0]}/>
                }

                {user.birth_date != null &&
                    <Badge count={<UserOutlined />} text={moment(user.birth_date).format('LL')} offset={[0,0]}/>
                }

                <Badge count={<MailOutlined />} text={user.email} offset={[0,0]}/>
                {user.email_verified_at == null?<Button type="text" onClick={() => sendConfirmationMail()}>Confirmer l'adresse mail</Button>:<></>}

                {user.vat_number != null &&
                    <Badge count={<MailOutlined />} text={user.vat_number} offset={[0,0]}/>
                }

                {user.mobile_phone_number != null &&
                    <Badge count={<MobileOutlined />} text={user.mobile_phone_number} offset={[0,0]}/>
                }
                
                {user.street != null & user.street_box != null ?
                    <Badge count={<EnvironmentOutlined />} text={user.street+", "+user.street_number+" - "+user.street_box} offset={[0,0]}/>
                    :
                    <Badge count={<EnvironmentOutlined />} text={user.street+", "+user.street_number} offset={[0,0]}/>
                }

                {user.zip_code != null &&
                    <Badge count={<EnvironmentOutlined />} text={user.zip_code + '-' + user.locality} offset={[0,0]}/> 
                }

                {user.invited == false && user.email != null && user.email_verified_at == null &&
                    <Button type="danger" icon={<MailOutlined />} onClick={inviteUserToJoinThePlateform}>Inviter</Button>
                }

                {user.invited == true && user.email != null && user.email_verified_at == null &&
                    <Button type="danger" icon={<MailOutlined />} onClick={inviteUserToJoinThePlateform}>Renvoyer l'invitation</Button>
                }

                <Row justify="space-around">
                    <Popconfirm
                        title="Voulez-vous vraiment supprimer cet utilisateur ?"
                        onConfirm={deleteUser}
                        okText="Oui"
                        cancelText="Non"
                    >
                        <Button style={{border:'none', color:'red'}}> Supprimer </Button>
                    </Popconfirm>
                    <ChangeUserDetails user={user} refresh_handler={refresh_handler}/>
                </Row>
            </Space>
            :
            <List className="cardList" size="small" dataSource={companies} renderItem={company => (
                    <List.Item>
                        <List.Item.Meta 
                            avatar={<FundProjectionScreenOutlined />}
                            title={company.company_name}
                            description={company.pivot.role==="ROLE_ADMIN"?"Administrateur":"Chauffeur"}
                        />
                    </List.Item>
                )}
                pagination={{
                    pageSize: 4,
                    hideOnSinglePage:true,
                    simple:true,
                    position:'top',
                }}
                locale={{emptyText:<Empty description="Aucune entreprise" image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}
            >
            </List>}
        </Card>
    )
}

//Carte des détails d'un formateur
export const UserProfileCard = ({user, refresh_handler}) => {
    return (
        <Card cover={<Avatar src={user.avatar_url} size={150} />}>
            <Meta title={user.last_name} description={user.first_name} />
            <Space direction="vertical" align='center' style={{rowGap:15, marginTop:15}}>
                
                {user.national_number != null &&
                    <Badge count={<UserOutlined />} text={user.national_number} offset={[0,0]}/>
                }

                {user.birth_date != null &&
                    <Badge count={<UserOutlined />} text={moment(user.birth_date).format('LL')} offset={[0,0]}/>
                }

                <Badge count={<MailOutlined />} text={user.email} offset={[0,0]}/>
                {user.email_verified_at == null?<Button type="text" onClick={() => sendConfirmationMail()}>Confirmer l'adresse mail</Button>:<></>}

                {user.vat_number != null &&
                    <Badge count={<MailOutlined />} text={user.vat_number} offset={[0,0]}/>
                }

                {user.mobile_phone_number != null &&
                    <Badge count={<MobileOutlined />} text={user.mobile_phone_number} offset={[0,0]}/>
                }
                
                {user.street != null & user.street_box != null ?
                    <Badge count={<EnvironmentOutlined />} text={user.street+", "+user.street_number+" - "+user.street_box} offset={[0,0]}/>
                    :
                    <Badge count={<EnvironmentOutlined />} text={user.street+", "+user.street_number} offset={[0,0]}/>
                }

                {user.zip_code != null &&
                    <Badge count={<EnvironmentOutlined />} text={user.zip_code + '-' + user.locality} offset={[0,0]}/> 
                }

                <Row justify="center" style={{marginTop:15}}>
                    <ChangeTrainerDetails trainer={user} refresh_handler={refresh_handler} />
                </Row>
            </Space>
        </Card>
    );
}

//Carte des détails d'une entreprise
export const CompanyDetailsCard = ({company, isAdmin, isCompanyAdmin, refresh_handler}) => {
    let history = useHistory();

    const deleteCompany = () => {
        API.delete(`api/companies/${company.id}`).then(()=>{
            isAdmin?history.push('/aclients'):history.push('/companies');
            message.success("L'entreprise a bien été supprimée !");
        }).catch((err)=>{
            message.error("Erreur lors de la suppression de l'entreprise !");
        })
    }

    return (
        <Card>
            <Meta title={company.company_name}/>
            <Space direction="vertical" size="middle">
                {company.street != "" &&
                <Row justify="center">
                    <Col span={4}>
                        <HomeOutlined />
                    </Col>
                    <Col span={14}>
                        <Space direction='vertical' size='1'>
                            <Text>{company.street+" "+company.street_number+", "+company.zip_code+ " "+ company.locality}</Text>
                            <Text>{company.country}</Text>
                        </Space>
                    </Col>
                </Row>}
                {company.vat_number != "" &&
                <Row justify="center">
                    <Col span={4}>
                        <FieldNumberOutlined />
                    </Col>
                    <Col span={14}>
                        <Text>{company.vat_number}</Text>
                    </Col>
                </Row>}
            </Space>
            {isCompanyAdmin && 
            <Row justify="space-around" style={{marginTop: 20}}>
                <Popconfirm
                    title="Voulez-vous vraiment supprimer cet entreprise ?"
                    onConfirm={deleteCompany}
                    okText="Oui"
                    cancelText="Non"
                >
                    <Button style={{border:'none', color:'red'}}> Supprimer </Button>
                </Popconfirm>
                <ChangeCompanyDetails company={company} refresh_handler={refresh_handler}/>
            </Row>}
        </Card>
    );
}

//Carte des détails d'une formation
export const TrainingDetailsCard = ({training, refresh_handler, isAdmin}) => {
    let history = useHistory();

    //Suppression de la formation
    const deleteTraining = () => {
        API.delete('/api/trainings/'+training.id).then(() => {
            history.push('/atrainings');
            message.success("Suppression de la formation !"); 
        }).catch((err)=>{
            message.success("Erreur lors de la suppression de la formation !");
        })
    }

    const reactivateTraining = () => {
        API.put(`/api/trainings/${training.id}`, { deleted_at: null }).then(response => {
            history.push('/atrainings');
            message.success("La formation à été réactivée");
        }).catch(err => {
            message.error("Une erreur c'est produite");
        });
    };

    const deleteMessage = training.deleted_at == null ? "Voulez-vous vraiment supprimer cette formation ?" : "Voulez-vous vraiment supprimer cette formation et toutes les choses qui sont liées ?";

    return (
        <Card>
            <Meta title={training.name} description={training.price+' €'}/>
            <Paragraph>{training.description}</Paragraph>
            <Row justify="space-around" style={{marginTop: 20}}>
                {isAdmin && <><Popconfirm
                    title={deleteMessage}
                    onConfirm={deleteTraining}
                    okText="Oui"
                    cancelText="Non"
                >
                    <Button style={{border:'none', color:'red'}}> Supprimer </Button>
                </Popconfirm>
                <ChangeTrainingDetails training={training} refresh_handler={refresh_handler}/>
                </>}
                {training.deleted_at != null &&
                <Button onClick={reactivateTraining} type="dashed" icon={<RollbackOutlined />}>
                    Réactiver
                </Button>
                }
            </Row>
        </Card>
    );
}

export default ProfileCard;