//Import des composants utiles
import React from 'react';
import API from '../api';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

//Import des composants de design
import { LeftOutlined } from '@ant-design/icons';
import { Form, Input, Card, Button, Row, message, Col } from 'antd';


//Page de connexion de l'utilisateur
class PasswordReset extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            email: "",
            password_confirm: "",
            password: "",
            token: this.getUrlVars()['token'],
            redirect: false
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    //Récupération des variables passé dans l'url
    getUrlVars() {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    //Vérification des champs
    verifyField(){
        if(this.state.password == "" || this.state.password.length < 8 || this.state.password != this.state.password_confirm)
            return false;

        if(this.state.email == "")
            return false;
        
        if(this.state.token == undefined || this.state.token == null || this.state.token == "")
            return false;

        return true;
    }

    //Prise en charge de la modification des champs
    handleChange(event){
        if(this._isMounted)
            this.setState({[event.target.name]: event.target.value})
    }

    //Prise en charge de la connection de l'utilisateur
    async save(){
        if(this.verifyField()){
            API.post('/reset-password',{
                email: this.state.email,
                password_confirmation: this.state.password_confirm,
                password: this.state.password,
                token: this.state.token
            })
            .then(() => {
                if(this._isMounted){
                    message.success("Mot de passe modifié !");
                    this.setState({redirect: true})
                }
            })                                                 
        }   
    }

    componentDidMount(){
        this._isMounted = true;
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    render(){
        if(this.state.redirect)
            return <Redirect to='/' />;
            
        return (
            <Row id="passwordReset" justify="center" align="middle">
                <Col span={8}>
                    <Card
                        title="Récupération de votre compte"
                        actions={[<Button type="primary" shape="round" size='large' onClick={this.save.bind(this)}>Valider la modification du mot de passe</Button>]}
                    >
                        <Link to="/" className="backbutton"><Button type="primary" icon={<LeftOutlined />}/></Link>
                        <Form layout="vertical"> 
                            <Form.Item name={"email"} label="Adresse Mail" rules={[{required: true, message: "Entrez votre email"}]}>     
                                <Input autoComplete={"username"} name={"email"} onChange={this.handleChange.bind(this)} value={this.state.email} placeholder={"Entrez votre email"}/>
                            </Form.Item>
                            <Form.Item name={"password"} label="Nouveau Mot de passe" rules={[{required: true, message: "Entrez un nouveau mot de passe"}]}>
                                <Input.Password name={"password"} onChange={this.handleChange.bind(this)} placeholder={"Entrez un nouveau mot de passe"}/>
                            </Form.Item>
                            <Form.Item name={"password_confirm"} label="Confirmation du mot de passe" rules={[{required: true, message: "Confirmez votre mot de passe"}]}>
                                <Input.Password name={"password_confirm"} onChange={this.handleChange.bind(this)} visibilityToggle={false} placeholder={"Confirmez votre mot de passe"}/>
                            </Form.Item>
                        </Form>                           
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user.profile,
        trainings: state.trainings.trainings,
        trainers: state.trainers.trainers,
        users: state.users.users
    };
};

export default connect(mapStateToProps)(PasswordReset);