//Import des composants utiles
import React from 'react';
import { Link } from 'react-router-dom';

//Import des composants de design
import { LeftOutlined } from '@ant-design/icons';
import { Card, Typography, Row, Col,Button } from 'antd';
const { Title } = Typography;

//Page de connexion de l'utilisateur
class Confirmation extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            message: "",       
        }

        //Vérification de l'état pour éviter les erreurs de mémoire
        this._isMounted = false;
    }

    //Récupération des variables passé dans l'url
    getUrlVars() {
        var vars = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    componentDidMount(){
        this._isMounted = true;

        if(this.getUrlVars()['verified'] === "1"){
            if(this._isMounted){
                this.setState({message: "Mail confirmé !"})
            }
        }else{
            if(this._isMounted){
                this.setState({message: "Mail de confirmation expiré ou mail déja confirmé !"})
            }
        }
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    render(){
        return (
            <Row id="confirmation" justify="center" align="middle">
                <Col span={12}>
                    <Card>
                        <Link to="/" className="backbutton"><Button type="primary" icon={<LeftOutlined />}/></Link>
                        <Title level={4} style={{textAlign:'center'}}>Confirmation de votre adresse mail</Title>
                        <Title level={3} style={{textAlign:'center', color:'#e3342f'}}>{this.state.message}</Title>
                    </Card>
                </Col>
            </Row>
        );
    }
}


export default Confirmation;